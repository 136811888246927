import React, { useState, useRef } from "react";
import "./CodeInput.scss";

function CodeInput({ onSubmit = () => {}, vertical = false, dark = false }) {
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const inputRefs = useRef(inputValues.map(() => React.createRef()));

  const handleKeyDown = (inputIndex, e) => {
    // Only allow numbers, alphabets, and backspace
    if (e.key.length !== 1 || !e.key.match(/^[0-9a-zA-Z]+$/)) {
      e.preventDefault();
      return;
    }

    let newInputValues = [...inputValues];
    newInputValues[inputIndex] = e.key.toUpperCase();
    setInputValues(newInputValues);

    // Move focus to next input if not the last input
    if (inputIndex < inputValues.length - 1) {
      inputRefs.current[inputIndex + 1].current.focus();
    }
  };

  const handleSubmit = () => {
    const code = inputValues.join("");
    if (code.length === 4) {
      onSubmit(code);
    }
  };

  return (
    <div
      className={`code_input ${
        vertical ? "code_input_vertical" : "code_input_horizontal"
      }`}
    >
      <div className="code_input_container">
        {inputValues.map((value, index) => (
          <input
            className={`${dark ? "code_input_dark" : ""}`}
            ref={inputRefs.current[index]}
            key={index}
            type="text"
            onKeyDown={(e) => handleKeyDown(index, e)}
            readOnly
            value={value}
          />
        ))}
      </div>
      <div className="code_input_submit">
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
}

export default CodeInput;
