import React, { useEffect, useState } from "react";

import "./InputGrid.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  decrementTimer,
  resetTimer,
  setPuzzleComplete,
  showAnswer,
  startTimer,
} from "Store/SpotTheDifference";
import { correctAnswers } from "StaticData/SpotTheDiff";
import { useNavigate } from "react-router-dom";

const uuid = require("uuid");

function InputGrid({ length = 10, width = 13 }) {
  const { answers, wrongAnsTimer } = useSelector(
    (state) => state.SpotDifference,
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [answer, setAnswer] = useState("");

  useEffect(() => {
    let interval;
    if (wrongAnsTimer.running && wrongAnsTimer.seconds > 0) {
      interval = setInterval(() => {
        dispatch(decrementTimer());
      }, 1000);
    } else {
      dispatch(resetTimer());
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [wrongAnsTimer.running, dispatch, wrongAnsTimer.seconds]);

  useEffect(() => {
    // check if puzzle is solved
    const solved = Object.values(answers).every((answer) => answer.show);
    if (solved) {
      setPuzzleComplete(true);
      setTimeout(() => {
        navigate("/ghtsd");
      }, 1500);
    }
  }, [answers, navigate]);

  const startWrongAnsTimer = () => {
    dispatch(startTimer(10));
  };

  const checkAnswer = () => {
    if (!answer) return;

    const answerExists = Object.keys(correctAnswers).includes(
      answer.toLowerCase(),
    );
    if (!answerExists) {
      startWrongAnsTimer();
    } else {
      const answerIndex = correctAnswers[answer.toLowerCase()].answerKey;
      dispatch(showAnswer(answerIndex));
    }

    setAnswer("");
  };

  return (
    <div className="input_grid">
      <div className="input_grid_container">
        {new Array(length)
          .fill(0)
          .map((_, i) =>
            new Array(width)
              .fill(0)
              .map((_, j) => (
                <input
                  className={
                    `input_grid_container_input` +
                    (answers[i].characters[j] &&
                      (answers[i].highlighted.includes(j)
                        ? " input_grid_container_input_border_highlighted"
                        : " input_grid_container_input_border"))
                  }
                  key={uuid.v4()}
                  readOnly
                  type="text"
                  value={answers[i].show ? answers[i].characters[j] : ""}
                />
              )),
          )}
      </div>

      <div className="input_grid_submit_answer">
        {wrongAnsTimer.running && (
          <div className="input_grid_wrong_answer_timer">
            <span className="input_grid_wrong_answer_timer_label">
              {" "}
              That’s not a word I want! Guess again in{" "}
            </span>
            <span className="input_grid_wrong_answer_timer_value">
              {" "}
              {wrongAnsTimer.seconds}{" "}
            </span>
          </div>
        )}
        <input
          type="text"
          onChange={(e) => {
            setAnswer(e.target.value);
          }}
          placeholder="Feed me a word..."
          value={answer}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !wrongAnsTimer.running) {
              checkAnswer();
            }
          }}
        />
        <div
          className={
            "input_grid_submit_answer_btn" +
            (wrongAnsTimer.running ? " disabled" : "")
          }
          onClick={checkAnswer}
        >
          SUBMIT
        </div>
      </div>
    </div>
  );
}

export default InputGrid;
