import React from "react";

import "./TreePuzzle.scss";

import TreeGrid from "Components/TreePuzzle/TreeGrid/TreeGrid";
import TreeBalance from "Components/TreePuzzle/TreeBalance/TreeBalance";
import { useDispatch, useSelector } from "react-redux";
import { quickSolveGame, resetGame } from "Store/TreePuzzle";
import { ImageUrls } from "Assets";
import {
  setSeenSolveTreePuzzlePopup,
  setSeenTreePuzzleInstructionPopup,
} from "Store/StageTwo";
import TreeBgPopup from "Components/TreeBgPopup/TreeBgPopup";
import CodeInput from "Components/CodeInput/CodeInput";
import QuickSolve from "Components/QuickSolve/QuickSolve";
import { useNavigate } from "react-router-dom";

const TREE_PUZZLE_CODE = "351Y";
const QUICK_SOLVE_CODE = "315T";

function TreePuzzle() {
  const { seenTreePuzzleInstructionPopup, seenSolveTreePuzzlePopup } =
    useSelector((state) => state.StageTwo);
  const { isComplete, quickSolveUsed } = useSelector(
    (state) => state.TreePuzzle,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = React.useState(true);
  const [showInstructions, setShowInstructions] = React.useState(true);

  const handleQuickSolve = (code) => {
    if (code === QUICK_SOLVE_CODE) {
      dispatch(quickSolveGame());
    }
  };

  return (
    <div className="tree_puzzle">
      {isComplete && showPopup && !seenSolveTreePuzzlePopup && (
        <TreeBgPopup
          contentArray={[
            {
              primaryText: "That’s It!",
              secondaryText: "The map of Whereville is complete...",
              buttonText: "NEXT",
            },
            {
              primaryText: "",
              secondaryText:
                "Tell your Host the final coordinates of Max's gift! But beware, wrong answers will add 2 minutes to your final time...",
              buttonText: "NEXT",
            },
            {
              primaryText: "",
              secondaryText:
                "When you provide the correct coordinates, your host will give you a secret code to enter above.",
              buttonText: "GOT IT",
            },
          ]}
          onClick={() => {
            setShowPopup(false);
            dispatch(setSeenSolveTreePuzzlePopup());
          }}
        />
      )}

      {showInstructions && !seenTreePuzzleInstructionPopup && (
        <TreeBgPopup
          contentArray={[
            {
              secondaryText: "Click on a cell to add/remove a tree",
              buttonText: "OK",
            },
          ]}
          onClick={() => {
            setShowInstructions(false);
            dispatch(setSeenTreePuzzleInstructionPopup());
          }}
        />
      )}

      {!isComplete ? (
        <button
          className="tree_puzzle_reset"
          onClick={() => {
            setShowPopup(true);
            dispatch(resetGame());
          }}
        >
          <img src={ImageUrls.StageTwo.TreePuzzle.reset} alt="" /> Reset
        </button>
      ) : (
        <div className="treepuzzle_codeInput">
          <CodeInput
            onSubmit={(code) => {
              if (code === TREE_PUZZLE_CODE) {
                navigate("/matf/two");
              }
            }}
          />
        </div>
      )}
      <div className="tree_puzzle_content">
        {!quickSolveUsed && <QuickSolve onClick={handleQuickSolve} top={2.5} />}
        <TreeBalance balance={9} />
        <TreeGrid />
      </div>
    </div>
  );
}

export default TreePuzzle;
