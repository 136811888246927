import React from "react";

import "./Jigsaw.scss";
import CodeInput from "Components/CodeInput/CodeInput";
import TreeBgPopup from "Components/TreeBgPopup/TreeBgPopup";
import { useDispatch } from "react-redux";
import {
  setCheckTreePuzzle,
  setJigsawSolved,
  toggleJigsawGame,
} from "Store/StageTwo";
import { RED_HIGHLIGHTER_STATE } from "Shared/Constants";

const JIGSAW_SOLVE_CODE = "485H";

function Jigsaw() {
  const [showPopup, setShowPopup] = React.useState(true);

  const dispatch = useDispatch();

  return (
    <>
      {showPopup && (
        <TreeBgPopup
          contentArray={[
            {
              primaryText: "",
              secondaryText:
                "When completed, show your Host and you will receive a secret code to enter above.",
              buttonText: "GOT IT",
            },
          ]}
          onClick={() => {
            setShowPopup(false);
          }}
        />
      )}
      <div className="jigsaw">
        <CodeInput
          onSubmit={(code) => {
            if (code === JIGSAW_SOLVE_CODE) {
              dispatch(setJigsawSolved());
              dispatch(toggleJigsawGame());
              dispatch(setCheckTreePuzzle(RED_HIGHLIGHTER_STATE.HIGHLIGHT));
            }
          }}
        />
        <iframe
          style={{
            width: "100%",
            height: "90%",
            borderStyle: "solid",
            borderWidth: "2px",
            borderColor: "#888",
            margin: "0",
            padding: "0",
          }}
          allowFullScreen={true}
          src="https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?frm=1&url=aHR0cHM6Ly91cGxvYWRzLXNzbC53ZWJmbG93LmNvbS82MmVkNWZiYmY4ODJjMzYyMjc4Mjc4MjQvNjUxY2RiMGYyZjJmZmZjZjNhYTVkZmFiX0ppZ3NhdyUyMFB1enpsZSUyMChDb2xvciUyMENvcnJlY3Rpb24pLmpwZ18obm9fcHJldmlld180KV8obm9wPTYwKQ~~&color=brown"
          title="Jigsaw Puzzle"
        >
          Jigsaw Puzzle
        </iframe>
      </div>
    </>
  );
}

export default Jigsaw;
