import StageFirst from "../Views/StageFirst";

const routes = [
  {
    path: "/ghtsd",
    element: <StageFirst />,
    title: "Stage First",
  },
];

export default routes;
