import StageTwo from "../Views/StageTwo";

const routes = [
  {
    path: "/mcrnd",
    element: <StageTwo />,
    title: "Stage Two",
  },
];

export default routes;
