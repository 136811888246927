import { ImageUrls } from "Assets";
import { RED_HIGHLIGHTER_STATE } from "Shared/Constants";
import {
  setCheckDiary,
  setCheckTreePuzzle,
  toggleJigsawGame,
  toggleShowDiaryImage,
  toggleShowLandOrdinanceImage,
  toggleShowLyingLandOwnersImage,
  toggleShowNotepadImage,
  toggleTreePuzzleGame,
} from "Store/StageTwo";

export const BaseImage = ImageUrls.StageTwo.stateOne.base;
export const BaseImageWithTreePuzzle = ImageUrls.StageTwo.stateTwo.base;

export const getBaseImage = (stateTwo) => {
  return stateTwo ? BaseImageWithTreePuzzle : BaseImage;
};

export const ObjectsToHighlight = {
  Notepad: {
    name: "Notepad",
    isVisible: (state) => {
      if (
        state.checkDiary === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkTreePuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(toggleShowNotepadImage());
    },
    position: {
      x: 59,
      y: 67,
    },
    height: "calc(2vh + 2vw)",
    width: "calc(2vh + 2vw)",
    getImage: (stateTwo) => {
      return stateTwo
        ? ImageUrls.StageTwo.stateTwo.notepadHighlighted
        : ImageUrls.StageTwo.stateOne.notepadHighlighted;
    },
    path: "",
  },
  Diary: {
    name: "Diary",
    isVisible: (state) => {
      if (state.checkTreePuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT)
        return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(toggleShowDiaryImage());
      dispatch(setCheckDiary(RED_HIGHLIGHTER_STATE.UNHIGHLIGHT));
    },
    position: {
      x: 49.5,
      y: 66,
    },
    height: "calc(3vh + 3vw)",
    width: "calc(3.4vh + 3.4vw)",
    getImage: (stateTwo) => {
      return stateTwo
        ? ImageUrls.StageTwo.stateTwo.diaryHightlighted
        : ImageUrls.StageTwo.stateOne.diaryHightlighted;
    },
    path: "",
  },
  LandOrdinance: {
    name: "LandOrdinance",
    isVisible: (state) => {
      if (
        state.checkDiary === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkTreePuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(toggleShowLandOrdinanceImage());
    },
    position: {
      x: 58,
      y: 45,
    },
    height: "calc(7vh + 7vw)",
    width: "calc(4.7vh + 4.7vw)",
    getImage: (stateTwo) => {
      return stateTwo
        ? ImageUrls.StageTwo.stateTwo.LandOrdinanceHighlighted
        : ImageUrls.StageTwo.stateOne.LandOrdinanceHighlighted;
    },
    path: "",
  },
  LyingLandowners: {
    name: "LyingLandowners",
    isVisible: (state) => {
      if (
        state.checkDiary === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkTreePuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(toggleShowLyingLandOwnersImage());
    },
    position: {
      x: 45,
      y: 45,
    },
    width: "calc(9vh + 9.4vw)",
    height: "calc(7vh + 6vw)",
    getImage: (stateTwo) => {
      return stateTwo
        ? ImageUrls.StageTwo.stateTwo.LyingLandOwnersHighlighted
        : ImageUrls.StageTwo.stateOne.LyingLandOwnersHighlighted;
    },
    path: "",
  },
  Puzzle: {
    name: "Puzzle",
    isVisible: (state) => {
      if (state.checkDiary === RED_HIGHLIGHTER_STATE.HIGHLIGHT) return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      if (state.jigsawSolved) {
        dispatch(toggleTreePuzzleGame());
        dispatch(setCheckTreePuzzle(RED_HIGHLIGHTER_STATE.UNHIGHLIGHT));
      } else {
        dispatch(toggleJigsawGame());
      }
    },
    position: {
      x: 41,
      y: 67,
    },
    height: "calc(2vh + 2vw)",
    width: "calc(5vh + 5vw)",
    getImage: (stateTwo) => {
      return stateTwo
        ? ImageUrls.StageTwo.stateTwo.CompletePuzzleHighlighted
        : ImageUrls.StageTwo.stateOne.IncompletePuzzleHighlighted;
    },
    path: "",
  },
};
