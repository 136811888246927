import { createSlice } from "@reduxjs/toolkit";
import { cellData, winningCellIds } from "StaticData/TreePuzzle";

const treePuzzle = createSlice({
  name: "treePuzzle",
  initialState: {
    cellData,
    treeBalance: 9,
    isComplete: false,
    quickSolveUsed: false,
  },
  reducers: {
    toggleTreeOnCell: (state, action) => {
      // if tree is being added decrease treeBalance
      if (!state.cellData[action.payload].showTree) {
        if (state.treeBalance === 0) return;
        state.treeBalance -= 1;
      }

      // if tree is being removed increase treeBalance
      if (state.cellData[action.payload].showTree) {
        state.treeBalance += 1;
      }

      state.cellData = {
        ...state.cellData,
        [action.payload]: {
          ...state.cellData[action.payload],
          showTree: !state.cellData[action.payload].showTree,
        },
      };
    },
    resetGame: (state) => {
      state.cellData = cellData;
      state.treeBalance = 9;
      state.isComplete = false;
    },
    completeGame: (state) => {
      state.isComplete = true;
    },
    quickSolveGame: (state) => {
      state.treeBalance = 0;
      state.quickSolveUsed = true;
      // hide all trees
      Object.keys(state.cellData).forEach((cellId) => {
        state.cellData = {
          ...state.cellData,
          [cellId]: {
            ...state.cellData[cellId],
            showTree: false,
          },
        };
      });

      // show all trees on correct cells
      winningCellIds.forEach((cellId) => {
        state.cellData = {
          ...state.cellData,
          [cellId]: {
            ...state.cellData[cellId],
            showTree: true,
          },
        };
      });
    },
  },
});

export const { toggleTreeOnCell, resetGame, completeGame, quickSolveGame } =
  treePuzzle.actions;

export default treePuzzle.reducer;
