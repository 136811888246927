import React from "react";
import "./Checkbox.scss"; // Import the CSS file for styling

const Checkbox = ({ label, checked, onChange }) => {
  return (
    <label className="custom-checkbox">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="checkmark"></span>
      {label}
    </label>
  );
};

export default Checkbox;
