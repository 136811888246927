import React from "react";

import "./RevealBox.scss";
import { useSelector, useDispatch } from "react-redux";
import { selectBox } from "Store/CrazyNumLogic";

function RevealBox({ BoxId, text }) {
  const { answersToReveal, selectedBox, wrongAnsBox, gameOver } = useSelector(
    (state) => state.CrazyNumLogic,
  );

  const dispatch = useDispatch();

  return (
    <div
      className={`reveal_box ${
        !gameOver
          ? BoxId === selectedBox && !answersToReveal[BoxId].isRevealed
            ? "reveal_box_selected"
            : "reveal_box_not_selected"
          : ""
      } ${
        gameOver
          ? BoxId === wrongAnsBox
            ? "reveal_box_wrong_selected"
            : "reveal_box_wrong_not_selected"
          : ""
      }`}
      onClick={() => {
        dispatch(selectBox(BoxId));
      }}
    >
      <div className="reveal_box__inner_left">
        <div className="reveal_box__inner_left__text">
          <p>{BoxId}</p>
        </div>
      </div>
      <div className="reveal_box__inner_right">
        <div
          className={`${
            gameOver && !answersToReveal[BoxId].isRevealed
              ? "reveal_box__inner_right__text"
              : ""
          }`}
        >
          {gameOver && !answersToReveal[BoxId].isRevealed ? (
            <p>{BoxId === selectedBox ? "Access Denied" : "???"}</p>
          ) : (
            <p>{answersToReveal[BoxId].isRevealed ? text : ""}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default RevealBox;
