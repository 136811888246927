import { createSlice } from "@reduxjs/toolkit";
import { answersToReveal } from "StaticData/CrazyNumLogic";

const crazyNumberLogic = createSlice({
  name: "crazyNumberLogic",
  initialState: {
    answersToReveal,
    isRunning: false,
    selectedBox: "A2",
    gameOver: false,
    wrongAnsBox: null,
    isCompleted: false,
    welcomePopupShown: false,
  },
  reducers: {
    startGame: (state) => {
      state.isRunning = true;
    },
    selectBox: (state, action) => {
      if (state.selectedBox === action.payload) {
        state.selectedBox = null;
      } else {
        state.selectedBox = action.payload;
      }
    },
    revealAnswer: (state, action) => {
      state.answersToReveal[action.payload].isRevealed = true;
    },
    revealAllAnswers: (state) => {
      Object.keys(state.answersToReveal).forEach((key) => {
        state.answersToReveal[key].isRevealed = true;
      });
    },
    setWrongAnsAndEndGame: (state, action) => {
      state.wrongAnsBox = action.payload;
      state.gameOver = true;
      state.isRunning = false;
    },
    resetGame: (state) => {
      state.answersToReveal = answersToReveal;
      state.isRunning = false;
      state.selectedBox = "A2";
      state.gameOver = false;
      state.wrongAnsBox = null;
    },
    gameCompleted: (state) => {
      state.isCompleted = true;
    },
    setWelcomePopupShown: (state) => {
      state.welcomePopupShown = true;
    },
  },
});

export const {
  startGame,
  selectBox,
  revealAnswer,
  revealAllAnswers,
  setWrongAnsAndEndGame,
  resetGame,
  gameCompleted,
  setWelcomePopupShown,
} = crazyNumberLogic.actions;

export default crazyNumberLogic.reducer;
