import { useDispatch } from "react-redux";

import CancelButton from "Components/CancelButton/CancelButton";

const ImagePopup = ({
  show,
  image,
  toggle,
  ImgHeight,
  ImgWidth,
  zIndex = 9,
  cancelTop,
  cancelRight,
  cancelZIndex,
}) => {
  const dispatch = useDispatch();

  if (!show) return null;

  return (
    <>
      <CancelButton
        onClick={() => {
          dispatch(toggle());
        }}
        top={cancelTop}
        right={cancelRight}
        zIndex={cancelZIndex}
      />
      <div
        onClick={(e) => {
          dispatch(toggle());
        }}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
          position: "fixed",
          zIndex: zIndex,
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",

          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            width: "90vw",
            overflow: "hidden",
          }}
        >
          <img
            style={{
              height: ImgHeight,
              width: ImgWidth,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            src={image}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default ImagePopup;
