import React, { useEffect } from "react";

import { ImageUrls } from "Assets";
import { gifList } from "./DoorGifs";
import { useDispatch } from "react-redux";
import { setDoorLockSolved, setStageFirstComplete } from "Store/StageFirst";
import { useNavigate } from "react-router-dom";

const uuid = require("uuid");

function DoorLock() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [digits, setdigits] = React.useState([
    {
      activeGif: 0,
      position: {
        top: "60",
        left: "42",
      },
      imgPosition: {
        top: "0",
        left: "0",
      },
    },
    {
      activeGif: 0,
      position: {
        top: "60",
        left: "48",
      },
      imgPosition: {
        top: "0",
        left: "5.7",
      },
    },
    {
      activeGif: 0,
      position: {
        top: "60",
        left: "55.5",
      },
      imgPosition: {
        top: "0",
        left: "13.3",
      },
    },
    {
      activeGif: 0,
      position: {
        top: "60",
        left: "61.5",
      },
      imgPosition: {
        top: "0",
        left: "19",
      },
    },
  ]);

  useEffect(() => {
    const code = digits
      .map((digit) => digit.activeGif)
      .join("")
      .toString();

    // check answer
    if (code === "4659") {
      dispatch(setDoorLockSolved());
      dispatch(setStageFirstComplete());
      navigate("/matf/one");
    }
  }, [digits, dispatch, navigate]);

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
          backgroundImage: `url(${ImageUrls.StageFirst.DoorLock.baseLock})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
          className="digitContianer"
        >
          {digits.map((digit, index) => {
            return (
              <div key={uuid.v4()}>
                <img
                  key={uuid.v4()}
                  style={{
                    position: "absolute",
                    top: `${digit.imgPosition.top}%`,
                    left: `${digit.imgPosition.left}%`,
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none",
                    userSelect: "none",
                    overflow: "hidden",
                  }}
                  src={gifList[digits[index].activeGif].gif}
                  alt=""
                />
                <div
                  key={uuid.v4()}
                  className="digit"
                  style={{
                    position: "absolute",
                    top: `${digit.position.top}%`,
                    left: `${digit.position.left}%`,
                    height: "18vh",
                    width: "5vw",
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                  onClick={() => {
                    let updatedActiveGif;
                    if (digits[index].activeGif === 9) {
                      updatedActiveGif = 0;
                    } else {
                      updatedActiveGif = digits[index].activeGif + 1;
                    }
                    digits[index].activeGif = updatedActiveGif;
                    setdigits([...digits]);
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default DoorLock;
