import FirewallScreen from "../Views/FirewallScreen";

const routes = [
  {
    path: "/",
    element: <FirewallScreen />,
    title: "Firewall Screen",
  },
];

export default routes;
