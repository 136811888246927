import React from "react";

import "./TreeBalance.scss";
import { useSelector } from "react-redux";
import { ImageUrls } from "Assets";

const uuid = require("uuid");

function TreeBalance() {
  const { treeBalance } = useSelector((state) => state.TreePuzzle);

  return (
    <div className="tree-balance_container">
      <div className="tree-balance">
        {new Array(treeBalance).fill(0).map((_, i) => (
          <img
            src={ImageUrls.StageTwo.TreePuzzle.tree}
            key={uuid.v4()}
            alt=""
          />
        ))}
      </div>
    </div>
  );
}

export default TreeBalance;
