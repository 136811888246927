import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { api } from "Services/Api/api";
import common from "./Common";
import SpotDifference from "./SpotTheDifference";
import CrazyNumLogic from "./CrazyNumLogic";
import TreePuzzle from "./TreePuzzle";
import WordSearch from "./WordSearch";
import StageFirst from "./StageFirst";
import StageTwo from "./StageTwo";
import { setupListeners } from "@reduxjs/toolkit/query";

const rootPersistConfig = {
  key: "root",
  storage: sessionStorage,
};

if (process.env.REACT_APP_SESSION_STORAGE_DISABLED) {
  rootPersistConfig.whitelist = ["common"];
} else {
  rootPersistConfig.whitelist = [
    "common",
    "SpotDifference",
    "CrazyNumLogic",
    "TreePuzzle",
    "WordSearch",
    "StageFirst",
    "StageTwo",
  ];
}

const reducers = combineReducers({
  common,
  SpotDifference,
  CrazyNumLogic,
  TreePuzzle,
  WordSearch,
  StageFirst,
  StageTwo,
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware);
    return middlewares;
  },
});

const persistor = persistStore(store);
setupListeners(store.dispatch);
export { store, persistor };
