export default async function submitRating(
  rating,
  comment,
  timestamp = new Date().toUTCString(),
) {
  return fetch(
    "https://script.google.com/macros/s/AKfycbzZGfcaTUPSpKuMDSd24_kT6naiAF2kfHVnG-bYfzfRujgsriBu3LIXT29eTrhDZM_D/exec",
    {
      method: "POST",
      body: JSON.stringify({
        timestamp,
        rating,
        comment,
      }),
    },
  );
}
