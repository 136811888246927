export const cellData = {
  0: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  1: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid black",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "40W",
    isBorderCell: true,
  },
  2: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid black",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "30W",
    isBorderCell: true,
  },
  3: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid black",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "20W",
    isBorderCell: true,
  },
  4: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid black",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "10W",
    isBorderCell: true,
  },
  5: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid black",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "0",
    isBorderCell: true,
  },
  6: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid black",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "10E",
    isBorderCell: true,
  },
  7: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid black",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "20E",
    isBorderCell: true,
  },
  8: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid black",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "30E",
    isBorderCell: true,
  },
  9: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid black",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "40E",
    isBorderCell: true,
  },
  10: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  11: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid black",
      borderBottom: "2px solid transparent",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "40N",
    isBorderCell: true,
  },
  12: {
    cellStyle: {
      backgroundColor: "rgba(255, 225, 25, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  13: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  14: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  15: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  16: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  17: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  18: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  19: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  20: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  21: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid black",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  22: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid black",
      borderBottom: "2px solid transparent",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "30N",
    isBorderCell: true,
  },
  23: {
    cellStyle: {
      backgroundColor: "rgba(255, 225, 25, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  24: {
    cellStyle: {
      backgroundColor: "rgba(255, 225, 25, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  25: {
    cellStyle: {
      backgroundColor: "rgba(240, 50, 230, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  26: {
    cellStyle: {
      backgroundColor: "rgba(240, 50, 230, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  27: {
    cellStyle: {
      backgroundColor: "rgba(240, 50, 230, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  28: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  29: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  30: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  31: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  32: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid black",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  33: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid black",
      borderBottom: "2px solid transparent",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "20N",
    isBorderCell: true,
  },
  34: {
    cellStyle: {
      backgroundColor: "rgba(230, 25, 75, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  35: {
    cellStyle: {
      backgroundColor: "rgba(230, 25, 75, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  36: {
    cellStyle: {
      backgroundColor: "rgba(230, 25, 75, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  37: {
    cellStyle: {
      backgroundColor: "rgba(240, 50, 230, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  38: {
    cellStyle: {
      backgroundColor: "rgba(245, 130, 49, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  39: {
    cellStyle: {
      backgroundColor: "rgba(245, 130, 49, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  40: {
    cellStyle: {
      backgroundColor: "rgba(245, 130, 49, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  41: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  42: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  43: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid black",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  44: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid black",
      borderBottom: "2px solid transparent",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "10N",
    isBorderCell: true,
  },
  45: {
    cellStyle: {
      backgroundColor: "rgba(230, 25, 75, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  46: {
    cellStyle: {
      backgroundColor: "rgba(240, 50, 230, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  47: {
    cellStyle: {
      backgroundColor: "rgba(240, 50, 230, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  48: {
    cellStyle: {
      backgroundColor: "rgba(240, 50, 230, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  49: {
    cellStyle: {
      backgroundColor: "rgba(245, 130, 49, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  50: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  51: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  52: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  53: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  54: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid black",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  55: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid black",
      borderBottom: "2px solid transparent",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "0",
    isBorderCell: true,
  },
  56: {
    cellStyle: {
      backgroundColor: "rgba(230, 25, 75, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  57: {
    cellStyle: {
      backgroundColor: "rgba(240, 50, 230, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  58: {
    cellStyle: {
      backgroundColor: "rgba(240, 50, 230, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  59: {
    cellStyle: {
      backgroundColor: "rgba(240, 50, 230, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  60: {
    cellStyle: {
      backgroundColor: "rgba(245, 130, 49, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  61: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  62: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  63: {
    cellStyle: {
      backgroundColor: "rgba(60, 180, 75, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  64: {
    cellStyle: {
      backgroundColor: "rgba(60, 180, 75, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  65: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid black",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  66: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid black",
      borderBottom: "2px solid transparent",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "10S",
    isBorderCell: true,
  },
  67: {
    cellStyle: {
      backgroundColor: "rgba(230, 25, 75, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  68: {
    cellStyle: {
      backgroundColor: "rgba(191, 239, 69, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  69: {
    cellStyle: {
      backgroundColor: "rgba(191, 239, 69, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  70: {
    cellStyle: {
      backgroundColor: "rgba(191, 239, 69, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  71: {
    cellStyle: {
      backgroundColor: "rgba(245, 130, 49, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  72: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  73: {
    cellStyle: {
      backgroundColor: "rgba(67, 99, 216, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  74: {
    cellStyle: {
      backgroundColor: "rgba(60, 180, 75, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  75: {
    cellStyle: {
      backgroundColor: "rgba(60, 180, 75, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  76: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid black",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  77: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid black",
      borderBottom: "2px solid transparent",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "20S",
    isBorderCell: true,
  },
  78: {
    cellStyle: {
      backgroundColor: "rgba(230, 25, 75, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  79: {
    cellStyle: {
      backgroundColor: "rgba(230, 25, 75, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  80: {
    cellStyle: {
      backgroundColor: "rgba(230, 25, 75, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  81: {
    cellStyle: {
      backgroundColor: "rgba(191, 239, 69, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  82: {
    cellStyle: {
      backgroundColor: "rgba(245, 130, 49, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  83: {
    cellStyle: {
      backgroundColor: "rgba(245, 130, 49, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  84: {
    cellStyle: {
      backgroundColor: "rgba(245, 130, 49, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  85: {
    cellStyle: {
      backgroundColor: "rgba(145, 30, 180, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  86: {
    cellStyle: {
      backgroundColor: "rgba(60, 180, 75, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  87: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid black",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  88: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid black",
      borderBottom: "2px solid transparent",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "30S",
    isBorderCell: true,
  },
  89: {
    cellStyle: {
      backgroundColor: "rgba(66, 212, 244, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  90: {
    cellStyle: {
      backgroundColor: "rgba(66, 212, 244, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  91: {
    cellStyle: {
      backgroundColor: "rgba(191, 239, 69, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  92: {
    cellStyle: {
      backgroundColor: "rgba(191, 239, 69, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  93: {
    cellStyle: {
      backgroundColor: "rgba(145, 30, 180, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  94: {
    cellStyle: {
      backgroundColor: "rgba(145, 30, 180, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  95: {
    cellStyle: {
      backgroundColor: "rgba(145, 30, 180, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  96: {
    cellStyle: {
      backgroundColor: "rgba(145, 30, 180, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  97: {
    cellStyle: {
      backgroundColor: "rgba(60, 180, 75, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid #b7b6b67d",
    },
  },
  98: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid black",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  99: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid black",
      borderBottom: "2px solid transparent",
    },
    cellStyleComplete: {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
    hint: "40S",
    isBorderCell: true,
  },
  100: {
    cellStyle: {
      backgroundColor: "rgba(66, 212, 244, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  101: {
    cellStyle: {
      backgroundColor: "rgba(66, 212, 244, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  102: {
    cellStyle: {
      backgroundColor: "rgba(191, 239, 69, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  103: {
    cellStyle: {
      backgroundColor: "rgba(145, 30, 180, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  104: {
    cellStyle: {
      backgroundColor: "rgba(145, 30, 180, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  105: {
    cellStyle: {
      backgroundColor: "rgba(60, 180, 75, 1)",
      borderLeft: "2px solid black",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  106: {
    cellStyle: {
      backgroundColor: "rgba(60, 180, 75, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  107: {
    cellStyle: {
      backgroundColor: "rgba(60, 180, 75, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid black",
      borderRight: "2px solid #b7b6b67d",
      borderBottom: "2px solid black",
    },
  },
  108: {
    cellStyle: {
      backgroundColor: "rgba(60, 180, 75, 1)",
      borderLeft: "2px solid #b7b6b67d",
      borderTop: "2px solid #b7b6b67d",
      borderRight: "2px solid black",
      borderBottom: "2px solid black",
    },
  },
  109: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid black",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  110: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  111: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid black",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  112: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid black",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  113: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid black",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  114: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid black",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  115: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid black",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  116: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid black",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  117: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid black",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  118: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid black",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  119: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid black",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
  120: {
    cellStyle: {
      backgroundColor: "",
      borderLeft: "2px solid transparent",
      borderTop: "2px solid transparent",
      borderRight: "2px solid transparent",
      borderBottom: "2px solid transparent",
    },
    isBorderCell: true,
  },
};

export const winningCellIds = [12, 36, 27, 51, 70, 101, 83, 96, 64];
