import { createSlice } from "@reduxjs/toolkit";
import { RED_HIGHLIGHTER_STATE } from "Shared/Constants";

const stageTwo = createSlice({
  name: "stageTwo",
  initialState: {
    // red highlighter variables
    checkDiary: RED_HIGHLIGHTER_STATE.HIGHLIGHT,
    checkTreePuzzle: RED_HIGHLIGHTER_STATE.NONE,

    // puzzles
    jigsawSolved: false,

    // memory
    seenTreePuzzleInstructionPopup: false,
    seenSolveTreePuzzlePopup: false,

    // show variabels are use to toggle pop up images
    showNotepadImage: false,
    showLandOrdinanceImage: false,
    showDiaryImage: false,
    showLyingLandownersImage: false,

    // show variabels are use to toggle pop up games
    showJigsawGame: false,
    showTreePuzzleGame: false,
  },
  reducers: {
    // red highlighter variables
    setCheckDiary: (state, action) => {
      state.checkDiary = action.payload;
    },
    setCheckTreePuzzle: (state, action) => {
      state.checkTreePuzzle = action.payload;
    },

    // setWardrobeLockSolved: (state) => {
    //   state.wardrobeLockSolved = true;
    // },
    // setDoorLockSolved: (state) => {
    //   state.doorLockSolved = true;
    // },
    toggleShowNotepadImage: (state) => {
      state.showNotepadImage = !state.showNotepadImage;
    },
    toggleShowLandOrdinanceImage: (state) => {
      state.showLandOrdinanceImage = !state.showLandOrdinanceImage;
    },
    toggleShowDiaryImage: (state) => {
      state.showDiaryImage = !state.showDiaryImage;
    },
    toggleShowLyingLandOwnersImage: (state) => {
      state.showLyingLandownersImage = !state.showLyingLandownersImage;
    },
    // Games
    toggleJigsawGame: (state) => {
      state.showJigsawGame = !state.showJigsawGame;
    },
    toggleTreePuzzleGame: (state) => {
      state.showTreePuzzleGame = !state.showTreePuzzleGame;
    },
    // Puzzles Solve State
    setJigsawSolved: (state) => {
      state.jigsawSolved = true;
    },
    // memory
    setSeenTreePuzzleInstructionPopup: (state) => {
      state.seenTreePuzzleInstructionPopup = true;
    },
    setSeenSolveTreePuzzlePopup: (state) => {
      state.seenSolveTreePuzzlePopup = true;
    },
  },
});

export const {
  setCheckDiary,
  setCheckTreePuzzle,

  toggleShowNotepadImage,
  toggleShowLandOrdinanceImage,
  toggleShowDiaryImage,
  toggleShowLyingLandOwnersImage,

  toggleJigsawGame,
  toggleTreePuzzleGame,
  setJigsawSolved,

  setSeenTreePuzzleInstructionPopup,
  setSeenSolveTreePuzzlePopup,
} = stageTwo.actions;

export default stageTwo.reducer;
