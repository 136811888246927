import LoadingScreen from "../Views/LoadingScreen";

const routes = [
  {
    path: "nfjdb",
    element: <LoadingScreen />,
    title: "Loading Screen",
  },
];

export default routes;
