export const STRINGS = {
  SOMETHING_WENT_WRONG: "Sorry, something went wrong.",
  OFFLINE_MESSAGE:
    "You appear to be offline. Please check your internet connection.",
};

export const RED_HIGHLIGHTER_STATE = {
  HIGHLIGHT: "HIGHLIGHT",
  UNHIGHLIGHT: "UNHIGHLIGHT",
  NONE: "NONE",
};
