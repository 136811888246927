import RatingScreen from "../Views/RatingScreen";

const routes = [
  {
    path: "/rate-us",
    element: <RatingScreen />,
    title: "Rating Screen",
  },
];

export default routes;
