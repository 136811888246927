import { ImageUrls } from "Assets";

export const gifList = [
  {
    num: 1,
    gif: ImageUrls.StageFirst.DoorLock.digits[0],
  },
  {
    num: 2,
    gif: ImageUrls.StageFirst.DoorLock.digits[1],
  },
  {
    num: 3,
    gif: ImageUrls.StageFirst.DoorLock.digits[2],
  },
  {
    num: 4,
    gif: ImageUrls.StageFirst.DoorLock.digits[3],
  },
  {
    num: 5,
    gif: ImageUrls.StageFirst.DoorLock.digits[4],
  },
  {
    num: 6,
    gif: ImageUrls.StageFirst.DoorLock.digits[5],
  },
  {
    num: 7,
    gif: ImageUrls.StageFirst.DoorLock.digits[6],
  },
  {
    num: 8,
    gif: ImageUrls.StageFirst.DoorLock.digits[7],
  },
  {
    num: 9,
    gif: ImageUrls.StageFirst.DoorLock.digits[8],
  },
  {
    num: 10,
    gif: ImageUrls.StageFirst.DoorLock.digits[9],
  },
];
