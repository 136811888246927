import { Navigate } from "react-router-dom";
import { FirewallScreen } from "Views/FirewallScreen";
import { LoadingScreen } from "Views/LoadingScreen";
import { RatingScreen } from "Views/RatingScreen";
import { StageFirst } from "Views/StageFirst";
import { StageTwo } from "Views/StageTwo";
import { VideoScreen } from "Views/Videos";

const DEFAULT_GUEST_ROUTE = "/";

export const guestRoutes = [
  ...RatingScreen,
  ...VideoScreen,
  ...LoadingScreen,
  ...FirewallScreen,
  ...StageFirst,
  ...StageTwo,
  {
    path: "*",
    element: <Navigate to={DEFAULT_GUEST_ROUTE} />,
  },
];

export const authenticatedRoutes = [
  {
    path: "*",
    element: <Navigate to={DEFAULT_GUEST_ROUTE} />,
  },
];
