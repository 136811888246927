import React, { useState } from "react";

import "./HowToPlay.scss";
import { ImageUrls } from "Assets";

function HowToPlay() {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <>
      {showHelp && (
        <div
          className="crazy-num-logic_how_to_play__help__container"
          onClick={() => {
            setShowHelp(false);
          }}
        >
          <div className="crazy-num-logic_how_to_play__help">
            <ul>
              <li>
                Using logic and the clues, determine the number located in each
                box.
              </li>
              <li>
                Each cell contains a number 1, 2, 3, 4, 5, 6, 7, 8, or 9.{" "}
              </li>
              <li>
                Only numbers are in this quiz. Only one number in each box.
              </li>
              <li>Adjacent includes diagonals.</li>
              <li>
                There cannot be two of the same number in a row or column.
              </li>
              <li>This is possible without guessing.</li>
            </ul>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          setShowHelp(!showHelp);
        }}
        className="crazy-num-logic_how_to_play"
      >
        <img src={ImageUrls.StageFirst.CrazyNumLogic.helpCircle} alt="" />
        <h3>How to play</h3>
      </div>
    </>
  );
}

export default HowToPlay;
