import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { completeGame, toggleTreeOnCell } from "Store/TreePuzzle";

import "./TreeGrid.scss";

import { winningCellIds } from "StaticData/TreePuzzle";
import { ImageUrls } from "Assets";

const uuid = require("uuid");

const MATRIX_SIZE = 11;

function TreeGrid() {
  const { cellData, isComplete } = useSelector((state) => state.TreePuzzle);
  const dispatch = useDispatch();

  useEffect(() => {
    function checkIfGameIsWon() {
      let isWon = true;
      winningCellIds.forEach((cellId) => {
        if (!cellData[cellId].showTree) isWon = false;
      });
      return isWon;
    }

    // check if game is won
    if (checkIfGameIsWon()) {
      dispatch(completeGame());
    }
  }, [cellData, dispatch]);

  return (
    <div className="tree_puzzle_grid_container">
      <div
        className="tree_puzzle_container"
        style={{
          gridTemplateColumns: `repeat(${MATRIX_SIZE}, 1fr)`,
        }}
      >
        {new Array(MATRIX_SIZE).fill(0).map((_, i) =>
          new Array(MATRIX_SIZE).fill(0).map((_, j) => {
            const cellId = i * MATRIX_SIZE + j;
            return (
              <div
                className="tree_puzzle_container_cell"
                key={uuid.v4()}
                style={{
                  ...(isComplete && cellData[cellId].cellStyleComplete
                    ? cellData[cellId].cellStyleComplete
                    : cellData[cellId].cellStyle),
                }}
                onClick={() => {
                  if (cellData[cellId].isBorderCell) return;
                  dispatch(toggleTreeOnCell(cellId));
                }}
              >
                <div className="tree_puzzle__container_row_item_content">
                  {cellData[cellId].showTree ? (
                    <img src={ImageUrls.StageTwo.TreePuzzle.tree} alt="" />
                  ) : cellData[cellId].hint && isComplete ? (
                    <p>{cellData[cellId].hint}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          }),
        )}
      </div>
    </div>
  );
}

export default TreeGrid;
