import { ImageUrls } from "Assets";
import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import "./Diary.scss";

function Diary() {
  return (
    <div className="diary">
      <TransformWrapper>
        <TransformComponent>
          <img
            className="diary_image"
            src={ImageUrls.StageTwo.Diary}
            alt="diary"
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
}

export default Diary;
