import { createSlice } from "@reduxjs/toolkit";
import { answers } from "StaticData/SpotTheDiff";

const spotDifference = createSlice({
  name: "spotDifference",
  initialState: {
    answers,
    wrongAnsTimer: {
      running: false,
      seconds: 0,
    },
    isCompleted: false,
  },
  reducers: {
    showAnswer: (state, action) => {
      state.answers = {
        ...state.answers,
        [action.payload]: {
          ...state.answers[action.payload],
          show: true,
        },
      };
    },
    startTimer: (state, action) => {
      state.wrongAnsTimer = { running: true, seconds: action.payload };
    },
    resetTimer: (state) => {
      state.wrongAnsTimer = { running: false, seconds: 0 };
    },
    decrementTimer: (state) => {
      state.wrongAnsTimer = {
        ...state.wrongAnsTimer,
        seconds: state.wrongAnsTimer.seconds - 1,
      };
    },
    setPuzzleComplete: (state) => {
      state.isCompleted = true;
    },
  },
});

export const {
  showAnswer,
  startTimer,
  resetTimer,
  decrementTimer,
  setPuzzleComplete,
} = spotDifference.actions;

export default spotDifference.reducer;
