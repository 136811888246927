import CodeInput from "Components/CodeInput/CodeInput";
import React, { useState } from "react";

import QuickSolveIcon from "Assets/BuildAssets/quickSolve.svg";

import "./QuickSolve.scss";

function QuickSolve({ onClick = () => {}, top = 1 }) {
  const [showInput, setShowInput] = useState(false);

  return (
    <div
      className="quick_solve"
      style={{
        top: `calc(${top}vh + ${top}vw)`,
      }}
    >
      {showInput && (
        <div className="quick_solve_content">
          <h5>
            IF YOUR TEAMMATE ALREADY SOLVED THIS PUZZLE, GET THE SECRET CODE
            FROM YOUR ELF AND ENTER IT HERE TO JUMP FORWARD.
          </h5>
          <CodeInput
            onSubmit={(code) => {
              setShowInput(false);
              onClick(code);
            }}
            vertical={true}
            dark={true}
          />
        </div>
      )}
      <div
        className="quick_solve_button"
        onClick={() => {
          setShowInput(!showInput);
        }}
      >
        <img src={QuickSolveIcon} alt="" />
      </div>
    </div>
  );
}

export default QuickSolve;
