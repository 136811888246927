import React from "react";

import "./SearchWord.scss";
import GridPaper from "Components/Search-word/GridPaper/GridPaper";
import { useDispatch, useSelector } from "react-redux";
import { setSeenWordSearchHint } from "Store/StageFirst";

function SearchWord() {
  const { seenWordSearchHint } = useSelector((state) => state.StageFirst);
  const dispatch = useDispatch();

  const [showInstructions, setShowInstructions] = React.useState(true);

  return (
    <div className="search_word">
      {showInstructions && !seenWordSearchHint && (
        <div className="search_word_popup_container">
          <div className="search_word_popup">
            <div className="search_word_popup_content">
              <h2>Click & drag to highlight letters</h2>
              <button
                className="search_word_popup_content_button"
                onClick={() => {
                  setShowInstructions(false);
                  dispatch(setSeenWordSearchHint());
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      <GridPaper />
    </div>
  );
}

export default SearchWord;
