import { ImageUrls } from "Assets";
import React from "react";

import "./RatingScreen.scss";
import submitRating from "./saveRating";

function RatingScreen({ stars = 5 }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [rating, setRating] = React.useState(0);
  const [comments, setComments] = React.useState("");

  const isStarFilled = (starNumber) => {
    if (starNumber <= rating) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    if (rating) {
      submitRating(rating, comments)
        .then((data) => {
          setIsSubmitting(false);
          setIsSubmitted(true);
          // redirect to a website
          window.location.href = "https://www.runaway.games/";
        })
        .catch((err) => {
          setIsSubmitting(false);
          setIsSubmitted(true);
          window.location.href = "https://www.runaway.games/";
        });
    }
  };

  return (
    <div className="rating_screen">
      <div className="logo">
        <img src={ImageUrls.logo} alt="" />
      </div>
      <div className="rating_screen_content">
        <div className="rating_screen_star_rating">
          <div className="rating_screen_star_rating_title">
            <span>How Was Your Escape?</span>
          </div>
          <div className="rating_screen_star_rating_stars">
            {new Array(stars).fill(0).map((_, index) => (
              <div
                className="rating_screen_star_rating_star"
                key={index}
                onClick={() => setRating(index + 1)}
              >
                <img
                  src={
                    isStarFilled(index + 1)
                      ? ImageUrls.starFilled
                      : ImageUrls.starNotfilled
                  }
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
        <div className="rating_screen_comments">
          <div className="rating_screen_comments_label">
            <span>Comments:</span>
          </div>
          <div className="rating_screen_comments_textarea">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="What did you like, dislike, or think we could improve on?"
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
            ></textarea>
          </div>
        </div>
        <div
          className={
            isSubmitted ? "rating_screen_submited" : "rating_screen_submit"
          }
        >
          <button onClick={handleSubmit}>
            {isSubmitting
              ? "SUBMITTING..."
              : isSubmitted
              ? "SUBMITTED"
              : "CONTINUE"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RatingScreen;
