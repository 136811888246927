import React from "react";

import FirewallBg from "Assets/BuildAssets/bg.jpg";

function FirewallScreen() {
  return (
    <div style={styles.body}>
      <div style={styles.imgContainer}>
        <img src={FirewallBg} alt="Background" style={styles.img} />
      </div>
      <div style={styles.gameContainer}>
        <iframe
          style={styles.iframe}
          allowFullScreen={true}
          src="https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?frm=1&url=aHR0cHM6Ly91cGxvYWRzLXNzbC53ZWJmbG93LmNvbS82MmVkNWZiYmY4ODJjMzYyMjc4Mjc4MjQvNjUyNGZiZWI1YWEwNjBlYmUxYjQ4OTA0X0hvdyUyMFRoZSUyMEtyaW5jaCUyMFN0b2xlJTIwQ2hyaXN0bWFzJTIwUG9zdGVyJTIwKEhvcml6b250YWwpLmpwZ18obm9fcHJldmlld180KV8obm9wPTEwKQ~~&color=brown"
          title="Jigsaw Puzzle"
        ></iframe>
      </div>
    </div>
  );
}

// Define the styles
const styles = {
  body: {
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "calc(0.5vh + 0.5vw)",
    width: "50vw",
    height: "100vh",
  },
  gameContainer: {
    padding: "calc(0.5vh + 0.5vw)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    height: "90%",
    objectFit: "cover",
  },
  iframe: {
    width: "50vw",
    height: "55vh",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "#888",
  },
};

export default FirewallScreen;
