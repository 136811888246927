import React, { useEffect, useState } from "react";

import "./CrazyNumLogic.scss";

import RevealBoxGrid from "Components/Crazy-num-logic/RevealBoxGrid/RevealBoxGrid";
import { useDispatch, useSelector } from "react-redux";
import {
  gameCompleted,
  resetGame,
  revealAllAnswers,
  revealAnswer,
  selectBox,
  setWelcomePopupShown,
  setWrongAnsAndEndGame,
  startGame,
} from "Store/CrazyNumLogic";
import HowToPlay from "Components/Crazy-num-logic/HowToPlay/HowToPlay";
import { useNavigate } from "react-router-dom";
import QuickSolve from "Components/QuickSolve/QuickSolve";
import { setCheckPrinter, toggleShowNumberLogicGame } from "Store/StageFirst";
import { RED_HIGHLIGHTER_STATE } from "Shared/Constants";

const QUICK_SOLVE_CODE = "475B";

function CrazyNumLogic() {
  const {
    isRunning,
    selectedBox,
    answersToReveal,
    gameOver,
    isCompleted,
    welcomePopupShown,
  } = useSelector((state) => state.CrazyNumLogic);

  const [showWelcomePopup, setShowWelcomePopup] = useState(true);
  const [puzzleCompleteText, setPuzzleCompleteText] = useState(
    isCompleted ? "Success!" : "Access granted. Printer operation resuming...",
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  useEffect(() => {
    let timeout;
    let timeoutTwo;
    if (isCompleted) {
      timeout = setTimeout(() => {
        setPuzzleCompleteText("Success!");
      }, 2000);

      timeoutTwo = setTimeout(() => {
        // close the printer puzzle
        dispatch(toggleShowNumberLogicGame());
      }, 3500);
    }

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeoutTwo);
    };
  }, [isCompleted, navigate, dispatch]);

  // make showWelcomePopup false after 1.5s
  useEffect(() => {
    const idOne = setTimeout(() => {
      setShowWelcomePopup(false);
    }, 4000);

    // timeout to show game over popup
    const idTwo = setTimeout(() => {
      dispatch(setWelcomePopupShown());
    }, 6000);

    //  clean both the timeouts
    return () => {
      clearTimeout(idOne);
      clearTimeout(idTwo);
    };
  }, [dispatch]);

  const handleKeyPress = (e) => {
    if (!selectedBox || !isRunning || answersToReveal[selectedBox].isRevealed)
      return;

    // check answer
    if (e.key === answersToReveal[selectedBox].answer) {
      dispatch(revealAnswer(selectedBox));
      // again selecing the selected box to unselect it
      dispatch(selectBox(selectedBox));
    } else {
      dispatch(setWrongAnsAndEndGame(selectedBox));
    }
  };

  const handleQuickSolve = (code) => {
    if (code === QUICK_SOLVE_CODE) {
      dispatch(revealAllAnswers());
      dispatch(gameCompleted());
      dispatch(setCheckPrinter(RED_HIGHLIGHTER_STATE.HIGHLIGHT));
    }
  };

  return (
    <>
      {showWelcomePopup && !welcomePopupShown ? (
        <div className={`crazy-num-logic_welcome_screen`}>
          <div
            className={`crazy-num-logic_welcome_screen_content animate_fade_in`}
          >
            <h1>
              The Krinch's firewall detects an unauthorized visitor. You must
              complete the login challenge...
            </h1>
          </div>
        </div>
      ) : (
        <div className="crazy-num-logic">
          <div
            className={`crazy-num-logic_screen ${
              !welcomePopupShown ? "animate_fade_in_2" : ""
            }`}
          >
            <HowToPlay />
            {!isCompleted && <QuickSolve onClick={handleQuickSolve} />}
            {!isRunning && !gameOver && (
              <button
                className={`crazy-num-logic_start_button`}
                onClick={() => {
                  dispatch(startGame());
                }}
              >
                START
              </button>
            )}

            {isRunning && (
              <div className="crazy-num-logic_input">
                <label>{selectedBox ? selectedBox : ""}</label>
                <input type="text" autoFocus value={""} />
              </div>
            )}

            {gameOver && (
              <button
                className="crazy-num-logic_try_again_button"
                onClick={() => {
                  dispatch(resetGame());
                }}
              >
                TRY&nbsp;AGAIN
              </button>
            )}

            {isCompleted && (
              <div className="crazy-num-logic_complete_popup_container">
                <div className="crazy-num-logic_complete_popup">
                  {puzzleCompleteText}
                </div>
              </div>
            )}

            <RevealBoxGrid />
          </div>
        </div>
      )}
    </>
  );
}

export default CrazyNumLogic;
