import React, { useState } from "react";
import LetterGrid from "../LetterGrid/LetterGrid";

import "./GridPaper.scss";
import { clearAllSelection, toggleisErasing } from "Store/WordSearch";
import { useDispatch, useSelector } from "react-redux";

import { ImageUrls } from "Assets";

function GridPaper() {
  const { isErasing } = useSelector((state) => state.WordSearch);
  const [showConfirmClearPopUp, setShowConfirmClearPopUp] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className="search_word_grid_paper">
      {showConfirmClearPopUp && (
        <div className="search_word_grid_paper_clear_popup_container">
          <div className="search_word_grid_paper_clear_popup">
            <h1>Are you sure you want to clear all?</h1>
            <div className="search_word_grid_paper_clear_popup_buttons">
              <button
                className="search_word_grid_paper_clear_popup_yes"
                onClick={() => {
                  dispatch(clearAllSelection());
                  setShowConfirmClearPopUp(false);
                }}
              >
                Yes
              </button>
              <button
                className="search_word_grid_paper_clear_popup_no"
                onClick={() => {
                  setShowConfirmClearPopUp(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="search_word_grid_paper_buttons">
        <button
          className="search_word_grid_paper_erase"
          onClick={() => {
            dispatch(toggleisErasing());
          }}
          style={{
            background: isErasing ? "#ffe500" : "transparent",
            boxShadow: isErasing ? "0px -8px 0px 0px #d3bd00 inset" : "none",
          }}
        >
          <img src={ImageUrls.StageFirst.SearchWord.erase} alt="" />
          <span>Eraser</span>
        </button>
        <button
          className="search_word_grid_paper_clear_all"
          onClick={() => {
            setShowConfirmClearPopUp(true);
          }}
        >
          <img src={ImageUrls.StageFirst.SearchWord.clear} alt="" />
          <span>Clear All</span>
        </button>
      </div>
      <LetterGrid />
    </div>
  );
}

export default GridPaper;
