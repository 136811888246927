export const answersToReveal = {
  A1: {
    id: "A1",
    answer: "1",
    text: `1 - Only odd numbers are in corners. There are no 9's in a corner. The corners rise starting at this box as you move clockwise.`,
    isRevealed: true,
  },
  B1: {
    id: "B1",
    answer: "2",
    text: `2 - The center number is equal to E3 divided by A4.`,
    isRevealed: false,
  },
  C1: {
    id: "C1",
    answer: "5",
    text: `5 - There are four 1's in the puzzle.`,
    isRevealed: false,
  },
  D1: {
    id: "D1",
    answer: "6",
    text: `6`,
    isRevealed: false,
  },
  E1: {
    id: "E1",
    answer: "3",
    text: `3 - A1 is adjacent to numbers that add up to 10. B2 is not greater than 4.`,
    isRevealed: false,
  },
  A2: {
    id: "A2",
    answer: "5",
    text: `5 - Only one column contains no numbers greater than 5.`,
    isRevealed: false,
  },
  B2: {
    id: "B2",
    answer: "3",
    text: `3 - C5 is a 4 or a 6. D4 is greater than 7.`,
    isRevealed: false,
  },
  C2: {
    id: "C2",
    answer: "2",
    text: `2 - Only one row contains no numbers greater than 5.`,
    isRevealed: false,
  },
  D2: {
    id: "D2",
    answer: "4",
    text: `4`,
    isRevealed: true,
  },
  E2: {
    id: "E2",
    answer: "1",
    text: `1`,
    isRevealed: false,
  },
  A3: {
    id: "A3",
    answer: "8",
    text: `8 - There is a 6 in either the second or fourth column.`,
    isRevealed: false,
  },
  B3: {
    id: "B3",
    answer: "5",
    text: `5 - Every row that contains an 8 also contains a 7.`,
    isRevealed: false,
  },
  C3: {
    id: "C3",
    answer: "3",
    text: `3 - There is an 8 located in 3 of the 5 rows.`,
    isRevealed: false,
  },
  D3: {
    id: "D3",
    answer: "7",
    text: `7`,
    isRevealed: false,
  },
  E3: {
    id: "E3",
    answer: "9",
    text: `9 -  I am not adjacent to more than one of any number.`,
    isRevealed: false,
  },
  A4: {
    id: "A4",
    answer: "3",
    text: `3`,
    isRevealed: false,
  },
  B4: {
    id: "B4",
    answer: "7",
    text: `7`,
    isRevealed: false,
  },
  C4: {
    id: "C4",
    answer: "1",
    text: `1 - The rightmost column adds up to 24.`,
    isRevealed: false,
  },
  D4: {
    id: "D4",
    answer: "8",
    text: `8`,
    isRevealed: true,
  },
  E4: {
    id: "E4",
    answer: "6",
    text: `6`,
    isRevealed: false,
  },
  A5: {
    id: "A5",
    answer: "7",
    text: `7 - B3 is the same as C1. The numbers in column B get larger as you move down in the column.`,
    isRevealed: false,
  },
  B5: {
    id: "B5",
    answer: "8",
    text: `8`,
    isRevealed: false,
  },
  C5: {
    id: "C5",
    answer: "4",
    text: `4 - C4 and A4 add up to 4.`,
    isRevealed: false,
  },
  D5: {
    id: "D5",
    answer: "1",
    text: `1`,
    isRevealed: false,
  },
  E5: {
    id: "E5",
    answer: "5",
    text: `5 - There is only one 9 in the puzzle. E3 is one number larger than B5.`,
    isRevealed: false,
  },
};
