import { createSlice } from "@reduxjs/toolkit";
import { RED_HIGHLIGHTER_STATE } from "Shared/Constants";

const stageFirst = createSlice({
  name: "stageFirst",
  initialState: {
    stageFirstComplete: false,
    // red highlighter variables
    checkPrinter: RED_HIGHLIGHTER_STATE.NONE,
    checkSpotTheDiff: RED_HIGHLIGHTER_STATE.NONE,
    checkNoseLock: RED_HIGHLIGHTER_STATE.NONE,
    checkWordPuzzle: RED_HIGHLIGHTER_STATE.NONE,

    // Locks
    wardrobeLockSolved: false,
    doorLockSolved: false,

    // memory
    seenWordSearchHint: false,
    seenWordSearchPopup: false,

    // show variabels are use to toggle pop up images
    showSpotTheDiffSolImage: false,
    showNoseLockImage: false,
    showLetterImage: false,
    showPrinterBubblePopup: false,
    showWardrobeBubblePopup: false,

    // show variabels are use to toggle pop up games
    showDoorLock: false,
    showSpotTheDiffGame: false,
    showNumberLogicGame: false,
    showWardrobeLock: false,
    showWordSearchGame: false,
  },
  reducers: {
    setCheckPrinter: (state, action) => {
      state.checkPrinter = action.payload;
    },
    setCheckSpotTheDiff: (state, action) => {
      state.checkSpotTheDiff = action.payload;
    },
    setCheckNoseLock: (state, action) => {
      state.checkNoseLock = action.payload;
    },
    setCheckWordPuzzle: (state, action) => {
      state.checkWordPuzzle = action.payload;
    },
    setWardrobeLockSolved: (state) => {
      state.wardrobeLockSolved = true;
    },
    setDoorLockSolved: (state) => {
      state.doorLockSolved = true;
    },
    toggleShowSpotTheDiffSolImage: (state) => {
      state.showSpotTheDiffSolImage = !state.showSpotTheDiffSolImage;
    },
    toggleNoseLockImage: (state) => {
      state.showNoseLockImage = !state.showNoseLockImage;
    },
    toggleShowLetterImage: (state) => {
      state.showLetterImage = !state.showLetterImage;
    },
    toggleShowPrinterBubblePopup: (state) => {
      state.showPrinterBubblePopup = !state.showPrinterBubblePopup;
    },
    toggleShowWardrobeBubblePopup: (state) => {
      state.showWardrobeBubblePopup = !state.showWardrobeBubblePopup;
    },
    toggleShowDoorLock: (state) => {
      state.showDoorLock = !state.showDoorLock;
    },
    toggleShowWardrobeLock: (state) => {
      state.showWardrobeLock = !state.showWardrobeLock;
    },
    toggleShowNumberLogicGame: (state) => {
      state.showNumberLogicGame = !state.showNumberLogicGame;
    },
    toggleShowSpotTheDiffGame: (state) => {
      state.showSpotTheDiffGame = !state.showSpotTheDiffGame;
    },
    toggleShowWordSearchGame: (state) => {
      state.showWordSearchGame = !state.showWordSearchGame;
    },
    setSeenWordSearchPopup: (state) => {
      state.seenWordSearchPopup = true;
    },
    setSeenWordSearchHint: (state) => {
      state.seenWordSearchHint = true;
    },
    setStageFirstComplete: (state) => {
      state.stageFirstComplete = true;
    },
  },
});

export const {
  setCheckPrinter,
  setCheckSpotTheDiff,
  setCheckNoseLock,
  setCheckWordPuzzle,

  toggleShowSpotTheDiffSolImage,
  toggleNoseLockImage,
  toggleShowLetterImage,
  toggleShowPrinterBubblePopup,
  setWardrobeLockSolved,
  setDoorLockSolved,
  toggleShowWardrobeBubblePopup,
  toggleShowDoorLock,
  toggleShowWardrobeLock,
  toggleShowNumberLogicGame,
  toggleShowSpotTheDiffGame,
  toggleShowWordSearchGame,
  setSeenWordSearchHint,
  setSeenWordSearchPopup,

  setStageFirstComplete,
} = stageFirst.actions;

export default stageFirst.reducer;
