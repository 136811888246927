import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import RevealBox from "../RevealBox/RevealBox";

import "./RevealBoxGrid.scss";
import { gameCompleted } from "Store/CrazyNumLogic";
import { setCheckPrinter } from "Store/StageFirst";
import { RED_HIGHLIGHTER_STATE } from "Shared/Constants";

const uuid = require("uuid");

function RevealBoxGrid({ rows = 5, columns = 5 }) {
  const { answersToReveal, isCompleted } = useSelector(
    (state) => state.CrazyNumLogic,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    function checkIfcomplete() {
      const allBoxesRevealed = Object.keys(answersToReveal).every(
        (key) => answersToReveal[key].isRevealed,
      );

      return allBoxesRevealed;
    }

    if (checkIfcomplete() && !isCompleted) {
      dispatch(gameCompleted());
      dispatch(setCheckPrinter(RED_HIGHLIGHTER_STATE.HIGHLIGHT));
    }
  }, [answersToReveal, dispatch, isCompleted]);

  return (
    <div className="reveal_box_grid">
      <div className="reveal_box_grid_container">
        {new Array(rows).fill(0).map((_, i) =>
          new Array(columns).fill(0).map((_, j) => {
            const BoxId = String.fromCharCode(65 + j) + (i + 1);
            return (
              <RevealBox
                key={uuid.v4()}
                BoxId={BoxId}
                text={answersToReveal[BoxId].text}
              />
            );
          }),
        )}
      </div>
    </div>
  );
}

export default RevealBoxGrid;
