import { ImageUrls } from "Assets";
import { RED_HIGHLIGHTER_STATE } from "Shared/Constants";
import {
  setCheckNoseLock,
  setCheckPrinter,
  setCheckSpotTheDiff,
  setCheckWordPuzzle,
  setSeenWordSearchPopup,
  toggleNoseLockImage,
  toggleShowDoorLock,
  toggleShowNumberLogicGame,
  toggleShowPrinterBubblePopup,
  toggleShowSpotTheDiffGame,
  toggleShowSpotTheDiffSolImage,
  toggleShowWardrobeLock,
  toggleShowWordSearchGame,
} from "Store/StageFirst";
import { setShowFirstColumnAndGrid } from "Store/WordSearch";

export const BaseImage = ImageUrls.StageFirst.noPaper.base;
export const BaseImageWithPaper = ImageUrls.StageFirst.withPaper.base;

export const getBaseImage = (isPaper) => {
  return isPaper ? BaseImageWithPaper : BaseImage;
};

export const ObjectsToHighlight = {
  Computer: {
    name: "Computer",
    isVisible: (_, state) => {
      if (
        state.checkPrinter === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkSpotTheDiff === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkNoseLock === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(toggleShowNumberLogicGame());
    },
    position: {
      x: 24,
      y: 51,
    },
    height: "calc(5vh + 5vw)",
    width: "calc(3.2vh + 3.2vw)",
    getImage: (withPaper) => {
      return withPaper
        ? ImageUrls.StageFirst.withPaper.computerHiglighted
        : ImageUrls.StageFirst.noPaper.computerHiglighted;
    },
    path: "/crazy-number-logic",
  },
  Computer2: {
    name: "Computer2",
    isVisible: (_, state) => {
      if (
        state.checkPrinter === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkSpotTheDiff === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkNoseLock === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(toggleShowNumberLogicGame());
    },
    position: {
      x: 20,
      y: 50,
    },
    height: "calc(2.5vh + 2.5vw)",
    width: "calc(2.5vh + 2.5vw)",
    getImage: (withPaper) => {
      return withPaper
        ? ImageUrls.StageFirst.withPaper.computerHiglighted
        : ImageUrls.StageFirst.noPaper.computerHiglighted;
    },
    path: "/crazy-number-logic",
  },
  SpotTheDifference: {
    name: "SpotTheDifference",
    isVisible: (_, state) => {
      if (
        state.checkPrinter === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkNoseLock === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      if (state.wardrobeLockSolved) {
        dispatch(toggleShowSpotTheDiffSolImage());
        dispatch(setCheckSpotTheDiff(RED_HIGHLIGHTER_STATE.UNHIGHLIGHT));
      } else {
        dispatch(toggleShowSpotTheDiffGame());
      }
    },
    position: {
      x: 55,
      y: 40,
    },
    height: "calc(4vh + 4vw)",
    width: "calc(7vh + 7vw)",
    getImage: (withPaper) => {
      return withPaper
        ? ImageUrls.StageFirst.withPaper.soptTheDiffHiglighted
        : ImageUrls.StageFirst.noPaper.soptTheDiffHiglighted;
    },
    path: "/spot-the-diff",
  },
  WardrobeLock: {
    name: "WardrobeLock",
    isVisible: (_, state) => {
      if (
        state.checkPrinter === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkSpotTheDiff === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkNoseLock === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(toggleShowWardrobeLock());
    },
    position: {
      x: 54,
      y: 50,
    },
    height: "calc(1.5vh + 1.5vw)",
    width: "calc(1.5vh + 1.5vw)",
    getImage: (withPaper) => {
      return withPaper
        ? ImageUrls.StageFirst.withPaper.wardrobeHiglighted
        : ImageUrls.StageFirst.noPaper.wardrobeHiglighted;
    },
    path: "/wardrobe-lock",
  },
  WordSearch: {
    name: "WordSearch",
    isVisible: (_, state) => {
      if (
        state.checkPrinter === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkSpotTheDiff === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkNoseLock === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(toggleShowWordSearchGame());

      console.log("state.checkWordPuzzle", state.checkWordPuzzle);

      if (state.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT) {
        dispatch(setCheckWordPuzzle(RED_HIGHLIGHTER_STATE.UNHIGHLIGHT));
      }
    },
    position: {
      x: 71,
      y: 45,
    },
    height: "calc(4vh + 4vw)",
    width: "calc(3vh + 3vw)",
    getImage: (withPaper) => {
      return withPaper
        ? ImageUrls.StageFirst.withPaper.WordPuzzleHiglighted
        : ImageUrls.StageFirst.noPaper.WordPuzzleHiglighted;
    },
    path: "/search-word",
  },
  NoseLock: {
    name: "NoseLock",
    isVisible: (_, state) => {
      if (
        state.checkPrinter === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkSpotTheDiff === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: (navigate, state, dispatch) => {
      dispatch(toggleNoseLockImage());
      if (state.checkNoseLock === RED_HIGHLIGHTER_STATE.HIGHLIGHT) {
        dispatch(setCheckSpotTheDiff(RED_HIGHLIGHTER_STATE.HIGHLIGHT));
        dispatch(setCheckNoseLock(RED_HIGHLIGHTER_STATE.NONE));
      }
    },
    position: {
      x: 77,
      y: 57,
    },
    height: "calc(3vh + 3vw)",
    width: "calc(1vh + 1vw)",
    getImage: (withPaper) => {
      return withPaper
        ? ImageUrls.StageFirst.withPaper.noseLockHiglighted
        : ImageUrls.StageFirst.noPaper.noseLockHiglighted;
    },
  },
  NoseLock2: {
    name: "NoseLock2",
    isVisible: (_, state) => {
      if (
        state.checkPrinter === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkSpotTheDiff === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: (navigate, state, dispatch) => {
      dispatch(toggleNoseLockImage());
      if (state.checkNoseLock === RED_HIGHLIGHTER_STATE.HIGHLIGHT) {
        dispatch(setCheckSpotTheDiff(RED_HIGHLIGHTER_STATE.HIGHLIGHT));
        dispatch(setCheckNoseLock(RED_HIGHLIGHTER_STATE.NONE));
      }
    },
    position: {
      x: 78,
      y: 53,
    },
    height: "calc(1vh + 1vw)",
    width: "calc(1vh + 1vw)",
    getImage: (withPaper) => {
      return withPaper
        ? ImageUrls.StageFirst.withPaper.noseLockHiglighted
        : ImageUrls.StageFirst.noPaper.noseLockHiglighted;
    },
  },
  DoorLock: {
    name: "DoorLock",
    isVisible: (_, state) => {
      if (
        state.checkPrinter === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkSpotTheDiff === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkNoseLock === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return true;
    },
    onClick: function (navigate, state, dispatch) {
      dispatch(toggleShowDoorLock());
    },
    position: {
      x: 77.5,
      y: 49,
    },
    height: "calc(1vh + 1vw)",
    width: "calc(1vh + 1vw)",
    getImage: (withPaper) => {
      return withPaper
        ? ImageUrls.StageFirst.withPaper.doorLockHiglighted
        : ImageUrls.StageFirst.noPaper.doorLockHiglighted;
    },
    path: "/door-lock",
  },
  Printer: {
    name: "Printer",
    isVisible: (numberLogicCompleted, state) => {
      if (
        state.checkSpotTheDiff === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkNoseLock === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
        state.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT
      )
        return false;
      return numberLogicCompleted;
    },
    onClick: (navigate, state, dispatch) => {
      if (state.seenWordSearchPopup) {
        dispatch(toggleShowWordSearchGame());
      } else {
        dispatch(toggleShowPrinterBubblePopup());
        dispatch(setCheckPrinter(RED_HIGHLIGHTER_STATE.UNHIGHLIGHT));
        dispatch(setCheckWordPuzzle(RED_HIGHLIGHTER_STATE.HIGHLIGHT));
        dispatch(setShowFirstColumnAndGrid());
        dispatch(setSeenWordSearchPopup());
      }
    },
    position: {
      x: 17,
      y: 57,
    },
    height: "calc(3vh + 3vw)",
    width: "calc(6vh + 6vw)",
    getImage: (withPaper) => {
      return withPaper
        ? ImageUrls.StageFirst.withPaper.printerHighlighted
        : BaseImage;
    },
  },
};
