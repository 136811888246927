import React, { useRef, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import "./SpotTheDiff.scss";

import InputGrid from "Components/Spot-the-diff/InputGrid/InputGrid";

import ImageContainer from "Components/Spot-the-diff/ImageContainer/ImageContainer";
import { ImageUrls } from "Assets";

function SpotTheDiff() {
  const [showModal, setShowModal] = useState(false);

  const RWrapperA = useRef();
  const RWrapperB = useRef();

  const onChangeRZPP = (refWrapper) => {
    if (RWrapperA.current) {
      RWrapperA.current.setTransform(
        refWrapper.state.positionX,
        refWrapper.state.positionY,
        refWrapper.state.scale,
      );
    }
    if (RWrapperB.current) {
      RWrapperB.current.setTransform(
        refWrapper.state.positionX,
        refWrapper.state.positionY,
        refWrapper.state.scale,
      );
    }
  };

  return (
    <>
      {showModal && (
        <div
          className="spot_the_diff_image_modal"
          onClick={(e) => {
            if (e.target.className !== "spot_the_diff_image_modal") return;
            setShowModal(false);
          }}
        >
          <div className="spot_the_diff_image_modal_container spot_the_diff_image_modal_container_image_a ">
            <TransformWrapper
              ref={RWrapperA}
              onPanning={onChangeRZPP}
              onZoom={onChangeRZPP}
            >
              <TransformComponent>
                <img
                  className="spot_the_diff_image_modal_container_image"
                  src={
                    ImageUrls.StageFirst.SpotTheDifference
                      .Spot_The_Difference_Version_A
                  }
                  alt="image_a"
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
          <div className="spot_the_diff_image_modal_container spot_the_diff_image_modal_container_image_b">
            <TransformWrapper
              ref={RWrapperB}
              onPanning={onChangeRZPP}
              onZoom={onChangeRZPP}
            >
              <TransformComponent>
                <img
                  className="spot_the_diff_image_modal_container_image"
                  src={
                    ImageUrls.StageFirst.SpotTheDifference
                      .Spot_The_Difference_Version_B
                  }
                  alt="image_a"
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      )}
      <div className="spot_the_diff">
        <ImageContainer
          showImage={() => {
            setShowModal(true);
          }}
          image={
            ImageUrls.StageFirst.SpotTheDifference.Spot_The_Difference_Version_A
          }
        />
        <InputGrid />
        <ImageContainer
          showImage={() => {
            setShowModal(true);
          }}
          image={
            ImageUrls.StageFirst.SpotTheDifference.Spot_The_Difference_Version_B
          }
        />
      </div>
    </>
  );
}

export default SpotTheDiff;
