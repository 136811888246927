import React from "react";

import "./TreeBgPopup.scss";

function TreeBgPopup({ contentArray, onClick }) {
  const [index, setIndex] = React.useState(0);
  return (
    <div className="tree_puzzle_win_popup_container">
      <div className="tree_puzzle_win_popup">
        <div className="tree_puzzle_win_popup_content">
          <h1>{contentArray[index].primaryText}</h1>
          <h2>{contentArray[index].secondaryText}</h2>
          {contentArray.length > 1 && (
            <div className="progress_dots">
              {contentArray.map((_, i) => (
                <div
                  key={i}
                  className={`progress_dot ${i === index ? "active" : ""}`}
                ></div>
              ))}
            </div>
          )}
          <button
            className="tree_puzzle_win_popup_content_button"
            onClick={
              index === contentArray.length - 1
                ? onClick
                : () => setIndex(index + 1)
            }
          >
            {contentArray[index].buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TreeBgPopup;
