export const letters = [
  "",
  "86",
  "24",
  "23",
  "82",
  "55",
  "11",
  "13",
  "87",
  "32",
  "68",
  "29",
  "72",
  "26",
  "61",
  "47",
  "11",
  "81",
  "26",
  "59",
  "97",
  "14",
  "51",
  "45",
  "75",
  "38",
  "65",
  "21",
  "A",
  "N",
  "N",
  "A",
  "N",
  "B",
  "T",
  "M",
  "M",
  "A",
  "I",
  "M",
  "I",
  "N",
  "I",
  "A",
  "M",
  "T",
  "B",
  "N",
  "A",
  "T",
  "M",
  "I",
  "O",
  "O",
  "10",
  "M",
  "T",
  "I",
  "T",
  "B",
  "A",
  "M",
  "A",
  "A",
  "B",
  "I",
  "T",
  "A",
  "O",
  "T",
  "M",
  "N",
  "A",
  "O",
  "M",
  "I",
  "B",
  "M",
  "A",
  "T",
  "T",
  "55",
  "N",
  "A",
  "A",
  "O",
  "N",
  "O",
  "N",
  "M",
  "T",
  "T",
  "I",
  "O",
  "I",
  "A",
  "A",
  "O",
  "B",
  "A",
  "T",
  "N",
  "B",
  "T",
  "O",
  "B",
  "N",
  "M",
  "89",
  "N",
  "I",
  "O",
  "O",
  "T",
  "B",
  "O",
  "N",
  "A",
  "O",
  "N",
  "B",
  "M",
  "I",
  "O",
  "O",
  "M",
  "B",
  "I",
  "M",
  "A",
  "I",
  "T",
  "I",
  "M",
  "A",
  "43",
  "T",
  "I",
  "N",
  "N",
  "A",
  "B",
  "M",
  "N",
  "M",
  "M",
  "M",
  "T",
  "O",
  "I",
  "A",
  "I",
  "A",
  "A",
  "N",
  "I",
  "T",
  "O",
  "N",
  "B",
  "A",
  "I",
  "82",
  "M",
  "O",
  "N",
  "N",
  "N",
  "I",
  "O",
  "I",
  "N",
  "I",
  "T",
  "M",
  "M",
  "T",
  "M",
  "M",
  "M",
  "T",
  "I",
  "I",
  "O",
  "N",
  "M",
  "T",
  "N",
  "O",
  "65",
  "B",
  "A",
  "T",
  "A",
  "N",
  "B",
  "O",
  "I",
  "M",
  "N",
  "N",
  "O",
  "M",
  "A",
  "T",
  "B",
  "I",
  "O",
  "A",
  "N",
  "M",
  "A",
  "N",
  "A",
  "O",
  "N",
  "55",
  "A",
  "T",
  "M",
  "A",
  "M",
  "N",
  "A",
  "T",
  "M",
  "I",
  "M",
  "B",
  "O",
  "N",
  "A",
  "I",
  "A",
  "T",
  "I",
  "M",
  "B",
  "N",
  "T",
  "M",
  "I",
  "M",
  "28",
  "O",
  "B",
  "N",
  "N",
  "N",
  "A",
  "A",
  "N",
  "O",
  "A",
  "M",
  "N",
  "A",
  "O",
  "M",
  "I",
  "T",
  "B",
  "I",
  "I",
  "T",
  "B",
  "I",
  "O",
  "B",
  "A",
  "91",
  "M",
  "N",
  "I",
  "B",
  "B",
  "A",
  "I",
  "M",
  "A",
  "N",
  "T",
  "M",
  "I",
  "A",
  "M",
  "N",
  "A",
  "O",
  "M",
  "T",
  "M",
  "O",
  "M",
  "B",
  "M",
  "O",
  "30",
  "A",
  "O",
  "I",
  "A",
  "T",
  "O",
  "I",
  "M",
  "B",
  "T",
  "O",
  "O",
  "M",
  "T",
  "N",
  "A",
  "O",
  "N",
  "N",
  "M",
  "B",
  "A",
  "I",
  "N",
  "T",
  "B",
  "86",
  "O",
  "M",
  "T",
  "T",
  "A",
  "N",
  "A",
  "M",
  "M",
  "N",
  "A",
  "O",
  "I",
  "T",
  "O",
  "M",
  "M",
  "A",
  "T",
  "B",
  "A",
  "M",
  "I",
  "B",
  "M",
  "N",
  "88",
  "T",
  "B",
  "O",
  "O",
  "O",
  "B",
  "B",
  "O",
  "B",
  "M",
  "M",
  "B",
  "I",
  "A",
  "T",
  "I",
  "B",
  "M",
  "I",
  "B",
  "T",
  "O",
  "B",
  "T",
  "O",
  "M",
  "11",
  "A",
  "B",
  "B",
  "B",
  "T",
  "A",
  "O",
  "T",
  "M",
  "N",
  "B",
  "M",
  "O",
  "N",
  "I",
  "N",
  "N",
  "M",
  "B",
  "T",
  "N",
  "A",
  "O",
  "O",
  "B",
  "T",
  "45",
  "A",
  "N",
  "T",
  "A",
  "N",
  "N",
  "T",
  "B",
  "I",
  "O",
  "M",
  "A",
  "I",
  "T",
  "I",
  "N",
  "O",
  "I",
  "A",
  "T",
  "A",
  "O",
  "A",
  "M",
  "O",
  "I",
  "34",
  "N",
  "A",
  "T",
  "B",
  "M",
  "M",
  "M",
  "I",
  "M",
  "O",
  "T",
  "A",
  "M",
  "B",
  "A",
  "M",
  "I",
  "A",
  "T",
  "N",
  "N",
  "B",
  "B",
  "O",
  "A",
  "I",
  "98",
  "A",
  "M",
  "A",
  "A",
  "B",
  "O",
  "B",
  "O",
  "B",
  "I",
  "B",
  "M",
  "A",
  "I",
  "O",
  "B",
  "M",
  "O",
  "I",
  "B",
  "B",
  "M",
  "T",
  "N",
  "B",
  "M",
  "90",
  "B",
  "I",
  "M",
  "B",
  "N",
  "O",
  "I",
  "N",
  "A",
  "N",
  "A",
  "B",
  "I",
  "N",
  "A",
  "M",
  "A",
  "T",
  "I",
  "M",
  "O",
  "M",
  "B",
  "T",
  "M",
  "A",
  "92",
  "O",
  "I",
  "O",
  "T",
  "T",
  "A",
  "M",
  "M",
  "B",
  "N",
  "O",
  "B",
  "M",
  "B",
  "B",
  "I",
  "B",
  "N",
  "A",
  "T",
  "A",
  "O",
  "A",
  "I",
  "B",
  "A",
  "77",
  "I",
  "A",
  "I",
  "N",
  "O",
  "I",
  "M",
  "N",
  "A",
  "I",
  "I",
  "O",
  "M",
  "B",
  "A",
  "I",
  "T",
  "M",
  "O",
  "A",
  "O",
  "M",
  "I",
  "I",
  "T",
  "O",
  "82",
  "A",
  "M",
  "N",
  "I",
  "A",
  "I",
  "T",
  "O",
  "I",
  "M",
  "M",
  "O",
  "O",
  "N",
  "O",
  "A",
  "T",
  "A",
  "N",
  "N",
  "M",
  "I",
  "T",
  "T",
  "O",
  "B",
  "76",
  "N",
  "B",
  "I",
  "A",
  "N",
  "B",
  "B",
  "O",
  "B",
  "A",
  "B",
  "T",
  "B",
  "N",
  "N",
  "B",
  "N",
  "I",
  "B",
  "O",
  "B",
  "A",
  "M",
  "B",
  "O",
  "N",
  "61",
  "T",
  "O",
  "N",
  "I",
  "I",
  "T",
  "I",
  "M",
  "N",
  "O",
  "M",
  "A",
  "A",
  "T",
  "T",
  "I",
  "M",
  "B",
  "A",
  "M",
  "T",
  "I",
  "A",
  "B",
  "A",
  "M",
  "29",
  "I",
  "M",
  "O",
  "M",
  "A",
  "M",
  "M",
  "O",
  "N",
  "O",
  "M",
  "T",
  "B",
  "N",
  "A",
  "B",
  "O",
  "N",
  "T",
  "N",
  "T",
  "N",
  "N",
  "M",
  "N",
  "A",
  "60",
  "I",
  "T",
  "A",
  "T",
  "O",
  "N",
  "A",
  "B",
  "I",
  "A",
  "O",
  "A",
  "N",
  "N",
  "M",
  "N",
  "A",
  "O",
  "I",
  "M",
  "O",
  "M",
  "B",
  "M",
  "B",
  "N",
  "81",
  "B",
  "N",
  "M",
  "A",
  "M",
  "N",
  "O",
  "M",
  "I",
  "O",
  "A",
  "I",
  "M",
  "I",
  "M",
  "M",
  "O",
  "T",
  "N",
  "A",
  "I",
  "N",
  "B",
  "I",
  "N",
  "A",
  "46",
  "M",
  "M",
  "T",
  "A",
  "B",
  "O",
  "A",
  "A",
  "O",
  "N",
  "O",
  "I",
  "T",
  "A",
  "O",
  "O",
  "B",
  "A",
  "O",
  "O",
  "O",
  "M",
  "M",
  "I",
  "O",
  "O",
  "29",
  "T",
  "N",
  "I",
  "I",
  "T",
  "I",
  "M",
  "I",
  "T",
  "M",
  "B",
  "O",
  "A",
  "I",
  "M",
  "I",
  "M",
  "A",
  "B",
  "M",
  "N",
  "A",
  "A",
  "N",
  "M",
  "B",
  "68",
  "N",
  "O",
  "B",
  "T",
  "O",
  "O",
  "T",
  "B",
  "M",
  "N",
  "T",
  "M",
  "O",
  "O",
  "A",
  "A",
  "I",
  "B",
  "M",
  "O",
  "A",
  "O",
  "O",
  "I",
  "O",
  "B",
  "37",
  "O",
  "T",
  "O",
  "N",
  "A",
  "M",
  "A",
  "N",
  "B",
  "O",
  "N",
  "O",
  "I",
  "O",
  "N",
  "N",
  "M",
  "A",
  "A",
  "I",
  "O",
  "B",
  "M",
  "M",
  "A",
  "O",
];

export const selectableColumns = {
  0: [
    0, 27, 54, 81, 108, 135, 162, 189, 216, 243, 270, 297, 324, 351, 378, 405,
    432, 459, 486, 513, 540, 567, 594, 621, 648, 675, 702, 729, 756, 783, 810,
  ],
  1: [
    1, 28, 55, 82, 109, 136, 163, 190, 217, 244, 271, 298, 325, 352, 379, 406,
    433, 460, 487, 514, 541, 568, 595, 622, 649, 676, 703, 730, 757, 784, 811,
  ],
  2: [
    2, 29, 56, 83, 110, 137, 164, 191, 218, 245, 272, 299, 326, 353, 380, 407,
    434, 461, 488, 515, 542, 569, 596, 623, 650, 677, 704, 731, 758, 785, 812,
  ],
  3: [
    3, 30, 57, 84, 111, 138, 165, 192, 219, 246, 273, 300, 327, 354, 381, 408,
    435, 462, 489, 516, 543, 570, 597, 624, 651, 678, 705, 732, 759, 786, 813,
  ],
  4: [
    4, 31, 58, 85, 112, 139, 166, 193, 220, 247, 274, 301, 328, 355, 382, 409,
    436, 463, 490, 517, 544, 571, 598, 625, 652, 679, 706, 733, 760, 787, 814,
  ],
  5: [
    5, 32, 59, 86, 113, 140, 167, 194, 221, 248, 275, 302, 329, 356, 383, 410,
    437, 464, 491, 518, 545, 572, 599, 626, 653, 680, 707, 734, 761, 788, 815,
  ],
  6: [
    6, 33, 60, 87, 114, 141, 168, 195, 222, 249, 276, 303, 330, 357, 384, 411,
    438, 465, 492, 519, 546, 573, 600, 627, 654, 681, 708, 735, 762, 789, 816,
  ],
  7: [
    7, 34, 61, 88, 115, 142, 169, 196, 223, 250, 277, 304, 331, 358, 385, 412,
    439, 466, 493, 520, 547, 574, 601, 628, 655, 682, 709, 736, 763, 790, 817,
  ],
  8: [
    8, 35, 62, 89, 116, 143, 170, 197, 224, 251, 278, 305, 332, 359, 386, 413,
    440, 467, 494, 521, 548, 575, 602, 629, 656, 683, 710, 737, 764, 791, 818,
  ],
  9: [
    9, 36, 63, 90, 117, 144, 171, 198, 225, 252, 279, 306, 333, 360, 387, 414,
    441, 468, 495, 522, 549, 576, 603, 630, 657, 684, 711, 738, 765, 792, 819,
  ],
  10: [
    10, 37, 64, 91, 118, 145, 172, 199, 226, 253, 280, 307, 334, 361, 388, 415,
    442, 469, 496, 523, 550, 577, 604, 631, 658, 685, 712, 739, 766, 793, 820,
  ],
  11: [
    11, 38, 65, 92, 119, 146, 173, 200, 227, 254, 281, 308, 335, 362, 389, 416,
    443, 470, 497, 524, 551, 578, 605, 632, 659, 686, 713, 740, 767, 794, 821,
  ],
  12: [
    12, 39, 66, 93, 120, 147, 174, 201, 228, 255, 282, 309, 336, 363, 390, 417,
    444, 471, 498, 525, 552, 579, 606, 633, 660, 687, 714, 741, 768, 795, 822,
  ],
  13: [
    13, 40, 67, 94, 121, 148, 175, 202, 229, 256, 283, 310, 337, 364, 391, 418,
    445, 472, 499, 526, 553, 580, 607, 634, 661, 688, 715, 742, 769, 796, 823,
  ],
  14: [
    14, 41, 68, 95, 122, 149, 176, 203, 230, 257, 284, 311, 338, 365, 392, 419,
    446, 473, 500, 527, 554, 581, 608, 635, 662, 689, 716, 743, 770, 797, 824,
  ],
  15: [
    15, 42, 69, 96, 123, 150, 177, 204, 231, 258, 285, 312, 339, 366, 393, 420,
    447, 474, 501, 528, 555, 582, 609, 636, 663, 690, 717, 744, 771, 798, 825,
  ],
  16: [
    16, 43, 70, 97, 124, 151, 178, 205, 232, 259, 286, 313, 340, 367, 394, 421,
    448, 475, 502, 529, 556, 583, 610, 637, 664, 691, 718, 745, 772, 799, 826,
  ],
  17: [
    17, 44, 71, 98, 125, 152, 179, 206, 233, 260, 287, 314, 341, 368, 395, 422,
    449, 476, 503, 530, 557, 584, 611, 638, 665, 692, 719, 746, 773, 800, 827,
  ],
  18: [
    18, 45, 72, 99, 126, 153, 180, 207, 234, 261, 288, 315, 342, 369, 396, 423,
    450, 477, 504, 531, 558, 585, 612, 639, 666, 693, 720, 747, 774, 801, 828,
  ],
  19: [
    19, 46, 73, 100, 127, 154, 181, 208, 235, 262, 289, 316, 343, 370, 397, 424,
    451, 478, 505, 532, 559, 586, 613, 640, 667, 694, 721, 748, 775, 802, 829,
  ],
  20: [
    20, 47, 74, 101, 128, 155, 182, 209, 236, 263, 290, 317, 344, 371, 398, 425,
    452, 479, 506, 533, 560, 587, 614, 641, 668, 695, 722, 749, 776, 803, 830,
  ],
  21: [
    21, 48, 75, 102, 129, 156, 183, 210, 237, 264, 291, 318, 345, 372, 399, 426,
    453, 480, 507, 534, 561, 588, 615, 642, 669, 696, 723, 750, 777, 804, 831,
  ],
  22: [
    22, 49, 76, 103, 130, 157, 184, 211, 238, 265, 292, 319, 346, 373, 400, 427,
    454, 481, 508, 535, 562, 589, 616, 643, 670, 697, 724, 751, 778, 805, 832,
  ],
  23: [
    23, 50, 77, 104, 131, 158, 185, 212, 239, 266, 293, 320, 347, 374, 401, 428,
    455, 482, 509, 536, 563, 590, 617, 644, 671, 698, 725, 752, 779, 806, 833,
  ],
  24: [
    24, 51, 78, 105, 132, 159, 186, 213, 240, 267, 294, 321, 348, 375, 402, 429,
    456, 483, 510, 537, 564, 591, 618, 645, 672, 699, 726, 753, 780, 807, 834,
  ],
  25: [
    25, 52, 79, 106, 133, 160, 187, 214, 241, 268, 295, 322, 349, 376, 403, 430,
    457, 484, 511, 538, 565, 592, 619, 646, 673, 700, 727, 754, 781, 808, 835,
  ],
  26: [
    26, 53, 80, 107, 134, 161, 188, 215, 242, 269, 296, 323, 350, 377, 404, 431,
    458, 485, 512, 539, 566, 593, 620, 647, 674, 701, 728, 755, 782, 809, 836,
  ],
};

export const selectableRows = {
  0: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26,
  ],
  1: [
    27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
    46, 47, 48, 49, 50, 51, 52, 53,
  ],
  2: [
    54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,
    73, 74, 75, 76, 77, 78, 79, 80,
  ],
  3: [
    81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    100, 101, 102, 103, 104, 105, 106, 107,
  ],
  4: [
    108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122,
    123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
  ],
  5: [
    135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149,
    150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161,
  ],
  6: [
    162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176,
    177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188,
  ],
  7: [
    189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203,
    204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215,
  ],
  8: [
    216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
    231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242,
  ],
  9: [
    243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257,
    258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269,
  ],
  10: [
    270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284,
    285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296,
  ],
  11: [
    297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311,
    312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323,
  ],
  12: [
    324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338,
    339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350,
  ],
  13: [
    351, 352, 353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365,
    366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377,
  ],
  14: [
    378, 379, 380, 381, 382, 383, 384, 385, 386, 387, 388, 389, 390, 391, 392,
    393, 394, 395, 396, 397, 398, 399, 400, 401, 402, 403, 404,
  ],
  15: [
    405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418, 419,
    420, 421, 422, 423, 424, 425, 426, 427, 428, 429, 430, 431,
  ],
  16: [
    432, 433, 434, 435, 436, 437, 438, 439, 440, 441, 442, 443, 444, 445, 446,
    447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457, 458,
  ],
  17: [
    459, 460, 461, 462, 463, 464, 465, 466, 467, 468, 469, 470, 471, 472, 473,
    474, 475, 476, 477, 478, 479, 480, 481, 482, 483, 484, 485,
  ],
  18: [
    486, 487, 488, 489, 490, 491, 492, 493, 494, 495, 496, 497, 498, 499, 500,
    501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, 512,
  ],
  19: [
    513, 514, 515, 516, 517, 518, 519, 520, 521, 522, 523, 524, 525, 526, 527,
    528, 529, 530, 531, 532, 533, 534, 535, 536, 537, 538, 539,
  ],
  20: [
    540, 541, 542, 543, 544, 545, 546, 547, 548, 549, 550, 551, 552, 553, 554,
    555, 556, 557, 558, 559, 560, 561, 562, 563, 564, 565, 566,
  ],
  21: [
    567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577, 578, 579, 580, 581,
    582, 583, 584, 585, 586, 587, 588, 589, 590, 591, 592, 593,
  ],
  22: [
    594, 595, 596, 597, 598, 599, 600, 601, 602, 603, 604, 605, 606, 607, 608,
    609, 610, 611, 612, 613, 614, 615, 616, 617, 618, 619, 620,
  ],
  23: [
    621, 622, 623, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634, 635,
    636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647,
  ],
  24: [
    648, 649, 650, 651, 652, 653, 654, 655, 656, 657, 658, 659, 660, 661, 662,
    663, 664, 665, 666, 667, 668, 669, 670, 671, 672, 673, 674,
  ],
  25: [
    675, 676, 677, 678, 679, 680, 681, 682, 683, 684, 685, 686, 687, 688, 689,
    690, 691, 692, 693, 694, 695, 696, 697, 698, 699, 700, 701,
  ],
  26: [
    702, 703, 704, 705, 706, 707, 708, 709, 710, 711, 712, 713, 714, 715, 716,
    717, 718, 719, 720, 721, 722, 723, 724, 725, 726, 727, 728,
  ],
  27: [
    729, 730, 731, 732, 733, 734, 735, 736, 737, 738, 739, 740, 741, 742, 743,
    744, 745, 746, 747, 748, 749, 750, 751, 752, 753, 754, 755,
  ],
  28: [
    756, 757, 758, 759, 760, 761, 762, 763, 764, 765, 766, 767, 768, 769, 770,
    771, 772, 773, 774, 775, 776, 777, 778, 779, 780, 781, 782,
  ],
  29: [
    783, 784, 785, 786, 787, 788, 789, 790, 791, 792, 793, 794, 795, 796, 797,
    798, 799, 800, 801, 802, 803, 804, 805, 806, 807, 808, 809,
  ],
  30: [
    810, 811, 812, 813, 814, 815, 816, 817, 818, 819, 820, 821, 822, 823, 824,
    825, 826, 827, 828, 829, 830, 831, 832, 833, 834, 835, 836,
  ],
};
