export const answers = {
  0: {
    show: false,
    characters: ["", "", "r", "e", "i", "n", "d", "e", "e", "r", "", "", ""],
    highlighted: [4],
  },
  1: {
    show: false,
    characters: ["", "s", "l", "e", "i", "g", "h", "", "", "", "", "", ""],
    highlighted: [4],
  },
  2: {
    show: false,
    characters: ["", "", "", "l", "a", "m", "p", "", "", "", "", "", ""],
    highlighted: [4],
  },
  3: {
    show: false,
    characters: ["w", "i", "n", "d", "o", "w", "", "", "", "", "", "", ""],
    highlighted: [4],
  },
  4: {
    show: false,
    characters: ["", "", "c", "a", "n", "d", "y", "c", "a", "n", "e", "", ""],
    highlighted: [4],
  },
  5: {
    show: false,
    characters: ["", "", "", "", "b", "e", "l", "l", "", "", "", "", ""],
    highlighted: [4],
  },
  6: {
    show: false,
    characters: ["", "", "", "s", "a", "n", "t", "a", "", "", "", "", ""],
    highlighted: [4],
  },
  7: {
    show: false,
    characters: ["", "", "", "s", "t", "a", "r", "", "", "", "", "", ""],
    highlighted: [4],
  },
  8: {
    show: false,
    characters: ["", "s", "n", "o", "w", "m", "a", "n", "", "", "", "", ""],
    highlighted: [3, 5],
  },
  9: {
    show: false,
    characters: ["", "", "g", "i", "n", "g", "e", "r", "b", "r", "e", "a", "d"],
    highlighted: [4],
  },
};

export const correctAnswers = {
  reindeer: { answerKey: 0 },
  sleigh: { answerKey: 1 },
  lamp: { answerKey: 2 },
  window: { answerKey: 3 },
  candy: { answerKey: 4 },
  candycane: { answerKey: 4 },
  "candy cane": { answerKey: 4 },
  bell: { answerKey: 5 },
  santa: { answerKey: 6 },
  star: { answerKey: 7 },
  snowman: { answerKey: 8 },
  gingerbread: { answerKey: 9 },
  "gingerbread man": { answerKey: 9 },
};
