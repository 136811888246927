import VideoScreen from "../Views/VideoScreen";

const routes = [
  {
    path: "/matf/one",
    element: <VideoScreen videoNumber={"one"} />,
    title: "Video Screen One",
  },
  {
    path: "/matf/two",
    element: <VideoScreen videoNumber={"two"} />,
    title: "Video Screen Two",
  },
];

export default routes;
