import React, { useEffect } from "react";

import "./LetterGrid.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  deselectLetter,
  selectLetter,
  toggleLetterSelection,
} from "Store/WordSearch";

const uuid = require("uuid");

const ROWS = 27;

function LetterGrid() {
  const {
    letters,
    selectedItems,
    isErasing,
    selectableRows,
    selectableColumns,
    showFirstColumnAndGrid,
  } = useSelector((state) => state.WordSearch);
  const dispatch = useDispatch();

  const [isSelecting, setIsSelecting] = React.useState(false);
  const [selectDirection, setSelectDirection] = React.useState(null);
  const [lastSelectedIndex, setLastSelectedIndex] = React.useState(null);
  const [startIndex, setStartIndex] = React.useState(null);
  const [selectableItems, setSelectableItems] = React.useState([]);

  const gridContainer = React.useRef(null);

  const [position, setPosition] = React.useState({ x: 0, y: 0 });

  useEffect(() => {
    const updatePosition = (e) => {
      // add position in grid paper to state
      let xOffSet = gridContainer?.current?.getBoundingClientRect()?.left;
      let yOffSet = gridContainer?.current?.getBoundingClientRect()?.top;

      setPosition({
        x: e.clientX - xOffSet,
        y: e.clientY - yOffSet,
      });
    };

    document.addEventListener("mousemove", updatePosition);

    return () => {
      document.removeEventListener("mousemove", updatePosition);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      setIsSelecting(true);
    });
    document.addEventListener("mouseup", (e) => {
      setIsSelecting(false);
      setSelectDirection(null);
      setLastSelectedIndex(null);
      setStartIndex(null);
      setSelectableItems([]);
    });
  }, []);

  const isFirstRowOrColumn = (index) => {
    return index <= ROWS || ((index - 0) % ROWS === 0 && index >= 0);
  };

  return (
    <div className="search_word_letter_grid_container" ref={gridContainer}>
      {/* for verticle and horizontal lines */}
      {showFirstColumnAndGrid && (
        <div className="search_word_letter_grid_cursor">
          <div
            className="search_word_letter_grid_vt"
            style={{ left: `${position.x}px` }}
          ></div>
          <div
            className="search_word_letter_grid_hl"
            style={{ top: `${position.y}px` }}
          ></div>
        </div>
      )}
      <div className="search_word_letter_grid">
        {letters.map((item, index) => (
          <div
            className="search_word_item"
            key={uuid.v4()}
            style={{
              backgroundColor: isFirstRowOrColumn(index)
                ? "black"
                : selectedItems[index]
                ? "yellow"
                : "transparent",
              fontWeight: isFirstRowOrColumn(index) ? "bold" : "normal",
              color: isFirstRowOrColumn(index) ? "white" : "black",
              opacity: isFirstRowOrColumn(index)
                ? showFirstColumnAndGrid
                  ? 1
                  : 0
                : 1,
            }}
            onMouseEnter={() => {
              if (isSelecting) {
                if (!selectDirection && lastSelectedIndex) {
                  if (Math.abs(index - lastSelectedIndex) >= ROWS) {
                    setSelectDirection("VERTICAL");

                    // detect column number
                    const columnNumber = index % ROWS;
                    // fill selectable items with column

                    setSelectableItems(selectableColumns[columnNumber]);
                  } else {
                    setSelectDirection("HORIZONTAL");

                    // detect row number
                    const rowNumber = Math.floor(index / ROWS);
                    // fill selectable items with row
                    setSelectableItems(selectableRows[rowNumber]);
                  }
                } else if (selectDirection === "VERTICAL") {
                  if (startIndex) {
                    // move down

                    if (
                      index > lastSelectedIndex &&
                      selectableItems.includes(index)
                    ) {
                      const toDispatch = [];

                      for (let i = startIndex; i <= index; i += ROWS) {
                        toDispatch.push(i);
                      }
                      isErasing
                        ? dispatch(deselectLetter(toDispatch))
                        : dispatch(selectLetter(toDispatch));
                      setLastSelectedIndex(index);
                    }
                    // move up
                    if (
                      index < lastSelectedIndex &&
                      selectableItems.includes(index)
                    ) {
                      const toDispatch = [];
                      for (let i = index; i <= startIndex; i += ROWS) {
                        toDispatch.push(i);
                      }
                      isErasing
                        ? dispatch(deselectLetter(toDispatch))
                        : dispatch(selectLetter(toDispatch));
                      setLastSelectedIndex(index);
                    }
                  }
                } else if (selectDirection === "HORIZONTAL") {
                  if (startIndex) {
                    // move right
                    if (
                      index > lastSelectedIndex &&
                      selectableItems.includes(index)
                    ) {
                      const toDispatch = [];
                      for (let i = startIndex; i <= index; i++) {
                        toDispatch.push(i);
                      }
                      isErasing
                        ? dispatch(deselectLetter(toDispatch))
                        : dispatch(selectLetter(toDispatch));
                      setLastSelectedIndex(index);
                    }
                    // move left
                    if (
                      index < lastSelectedIndex &&
                      selectableItems.includes(index)
                    ) {
                      const toDispatch = [];
                      for (let i = index; i <= startIndex; i++) {
                        toDispatch.push(i);
                      }
                      isErasing
                        ? dispatch(deselectLetter(toDispatch))
                        : dispatch(selectLetter(toDispatch));
                      setLastSelectedIndex(index);
                    }
                  }
                }
              }
            }}
            onMouseDown={() => {
              dispatch(toggleLetterSelection({ id: index, show: !isErasing }));
              setStartIndex(index);
              setLastSelectedIndex(index);
            }}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LetterGrid;
