import React, { useRef, useState } from "react";

import "./VideoScreen.scss";
import { ImageUrls, VideoUrls } from "Assets";
import { videosNavigater } from "./VideoNavigater";
import { useNavigate } from "react-router-dom";

import PlayIcon from "Assets/BuildAssets/play.svg";
import SkipIcon from "Assets/BuildAssets/skip.svg";
import MuteIcon from "Assets/BuildAssets/mute.svg";
import UnmuteIcon from "Assets/BuildAssets/unmute.svg";

function VideoScreen({ videoNumber = "One" }) {
  // video controls
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setPlaying(!playing);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setMuted(!muted);
    }
  };

  const handleVideoEnd = () => {
    if (videosNavigater[videoNumber].onEndPath) {
      navigate(videosNavigater[videoNumber].onEndPath);
    }
  };

  const navigate = useNavigate();
  return (
    <>
      {/* Buttons */}
      <div
        style={{
          position: "absolute",
          bottom: "5%",
          left: "5%",
          zIndex: 10,
        }}
        onClick={toggleMute}
        className={`videoScreenButton ${
          muted ? "videoScreenButton_Yellow" : "videoScreenButton_Red"
        }`}
      >
        {muted ? (
          <>
            <span>UNMUTE</span>
            <img src={UnmuteIcon} alt="" />
          </>
        ) : (
          <>
            <span>MUTE</span>
            <img src={MuteIcon} alt="" />
          </>
        )}
      </div>

      {!playing && (
        <div
          style={{
            position: "absolute",
            bottom: "50%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 10,
          }}
          onClick={togglePlay}
          className="videoScreenButton videoScreenButton_round"
        >
          <img className="player_icon" src={PlayIcon} alt="" />
        </div>
      )}

      <div
        style={{
          position: "absolute",
          bottom: "5%",
          right: "5%",
          zIndex: 10,
        }}
        onClick={handleVideoEnd}
        className="videoScreenButton videoScreenButton_Red"
      >
        <span>SKIP</span>
        <img src={SkipIcon} alt="" />
      </div>

      <div className="video_screen">
        <video
          ref={videoRef}
          poster={ImageUrls.videoThumbnails[videoNumber]}
          muted={muted}
          onEnded={handleVideoEnd}
        >
          <source src={VideoUrls[videoNumber]} type="video/mp4" />
        </video>
      </div>
    </>
  );
}

export default VideoScreen;
