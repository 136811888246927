import React from "react";

import "./ImageContainer.scss";

function ImageContainer({ image, showImage }) {
  return (
    <div onClick={showImage} className="spot_the_diff_image_container">
      <img src={image} alt="Spot the difference" />
    </div>
  );
}

export default ImageContainer;
