import React, { useEffect } from "react";

import "./StageTwo.scss";
import { ObjectsToHighlight, getBaseImage } from "./Highlighter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImagePopup from "Components/ImagePopup/ImagePopup";
import { ImageUrls } from "Assets";
import {
  setCheckDiary,
  setCheckTreePuzzle,
  toggleJigsawGame,
  toggleShowDiaryImage,
  toggleShowLandOrdinanceImage,
  toggleShowLyingLandOwnersImage,
  toggleShowNotepadImage,
  toggleTreePuzzleGame,
} from "Store/StageTwo";
import GamePopUp from "Components/GamePopup/GamePopup";
import Jigsaw from "Components/Jigsaw/Jigsaw";
import TreePuzzle from "Components/TreePuzzle/Puzzle/TreePuzzle";
import { RED_HIGHLIGHTER_STATE } from "Shared/Constants";
import InformationPopup from "Components/InformationPopup/InformationPopup";
import Diary from "Components/Diary/Diary";

const uuid = require("uuid");

function StageTwo() {
  const StageTwoState = useSelector((state) => state.StageTwo);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [activeImage, setActiveImage] = React.useState(
    getBaseImage(StageTwoState.jigsawSolved),
  );

  useEffect(() => {
    if (StageTwoState.checkDiary === RED_HIGHLIGHTER_STATE.HIGHLIGHT) {
      setActiveImage(ImageUrls.StageTwo.stateOne.redHighlightDiary);
    } else if (StageTwoState.checkDiary === RED_HIGHLIGHTER_STATE.UNHIGHLIGHT) {
      setActiveImage(getBaseImage(StageTwoState.jigsawSolved));
      dispatch(setCheckDiary(RED_HIGHLIGHTER_STATE.NONE));
    }
  }, [StageTwoState.checkDiary, StageTwoState.jigsawSolved, dispatch]);

  useEffect(() => {
    if (StageTwoState.checkTreePuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT) {
      setActiveImage(ImageUrls.StageTwo.stateTwo.redHighlightCompletPuzzle);
    } else if (
      StageTwoState.checkTreePuzzle === RED_HIGHLIGHTER_STATE.UNHIGHLIGHT
    ) {
      setActiveImage(getBaseImage(StageTwoState.jigsawSolved));
      dispatch(setCheckTreePuzzle(RED_HIGHLIGHTER_STATE.NONE));
    }
  }, [StageTwoState.checkTreePuzzle, StageTwoState.jigsawSolved, dispatch]);

  return (
    <>
      <InformationPopup />
      {/* Image Popups */}
      <ImagePopup
        show={StageTwoState.showNotepadImage}
        image={ImageUrls.StageTwo.Notepad}
        toggle={toggleShowNotepadImage}
        ImgHeight="100%"
        ImgWidth=""
        cancelRight={31}
      />
      <ImagePopup
        show={StageTwoState.showLandOrdinanceImage}
        image={ImageUrls.StageTwo.LandOrdinance}
        toggle={toggleShowLandOrdinanceImage}
        ImgHeight="100%"
        ImgWidth=""
        cancelRight={31}
      />
      <ImagePopup
        show={StageTwoState.showLyingLandownersImage}
        image={ImageUrls.StageTwo.LyingLandOwners}
        toggle={toggleShowLyingLandOwnersImage}
        ImgHeight="100%"
        ImgWidth=""
        cancelRight={12.5}
        cancelTop={2.5}
      />
      {/* Game Pop-ups */}
      <GamePopUp
        show={StageTwoState.showDiaryImage}
        toggle={() => {
          dispatch(toggleShowDiaryImage());
        }}
      >
        <Diary />
      </GamePopUp>
      <GamePopUp
        show={StageTwoState.showJigsawGame}
        toggle={() => {
          dispatch(toggleJigsawGame());
        }}
      >
        <Jigsaw />
      </GamePopUp>
      <GamePopUp
        show={StageTwoState.showTreePuzzleGame}
        toggle={() => {
          dispatch(toggleTreePuzzleGame());
        }}
      >
        <TreePuzzle />
      </GamePopUp>
      {/* Object highlighter */}
      {Object.values(ObjectsToHighlight).map((object) => {
        if (!object.isVisible(StageTwoState)) {
          return <div key={uuid.v4()}></div>;
        }

        return (
          <div
            key={uuid.v4()}
            style={{
              position: "absolute",
              top: `${object.position.y}%`,
              left: `${object.position.x}%`,
              transform: "translate(-50%, -50%)",
              width: object.width,
              height: object.height,
              cursor: "pointer",
            }}
            onMouseEnter={() => {
              // disable it if check printer is on
              if (
                StageTwoState.checkDiary === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
                StageTwoState.checkTreePuzzle ===
                  RED_HIGHLIGHTER_STATE.HIGHLIGHT
              )
                return;
              setActiveImage(object.getImage(StageTwoState.jigsawSolved));
            }}
            onMouseLeave={() => {
              // disable it if check printer is on
              if (
                StageTwoState.checkDiary === RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
                StageTwoState.checkTreePuzzle ===
                  RED_HIGHLIGHTER_STATE.HIGHLIGHT
              )
                return;
              setActiveImage(getBaseImage(StageTwoState.jigsawSolved));
            }}
            onClick={() => {
              object.onClick(navigate, StageTwoState, dispatch);
            }}
          ></div>
        );
      })}
      {/* BaseImage */}
      <div
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        }}
        className="stage_first"
      >
        <img
          style={{
            height: "100%",
            width: "100%",
          }}
          src={activeImage}
          alt=""
        />
      </div>
    </>
  );
}

export default StageTwo;
