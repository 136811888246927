import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { ImageUrls } from "Assets";

import "./LoadingScreen.scss";

import Loader from "Assets/BuildAssets/Krinch’s face (hat).PNG";

function LoadingScreen() {
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    function getAllImageUrls(obj) {
      const result = [];

      // Helper function to recursively traverse the object
      function traverse(obj) {
        for (const key in obj) {
          if (typeof obj[key] === "string") {
            // If the value is a string (URL), add it to the result
            result.push(obj[key]);
          } else if (typeof obj[key] === "object") {
            // If the value is an object, recursively traverse it
            traverse(obj[key]);
          }
        }
      }

      // Start the traversal with the initial object
      traverse(obj);

      return result;
    }

    (async function () {
      const promises = getAllImageUrls(ImageUrls).map((src) => {
        return new Promise(function (resolve, reject) {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });
      await Promise.all(promises);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return (
      <div className="loader" style={{}}>
        <img src={Loader} alt="" />
      </div>
    );
  } else {
    return <Navigate to={"/ghtsd"} />;
  }
}

export default LoadingScreen;
