import React, { useEffect } from "react";

import "./WardrobeLock.scss";
import { ImageUrls } from "Assets";
import { useDispatch, useSelector } from "react-redux";
import {
  setCheckNoseLock,
  setWardrobeLockSolved,
  toggleShowWardrobeBubblePopup,
  toggleShowWardrobeLock,
} from "Store/StageFirst";
import { RED_HIGHLIGHTER_STATE } from "Shared/Constants";

const uuid = require("uuid");

const buttonRows = [5, 6, 6, 6, 4];

const UNLOCKED = "UNLOCKED";
const ANSWER = "ABOMINATION";
const INCORRECT = "INCORRECT";
const INPUT_PLACEHOLDER = "ENTER 11-LETTER PASSWORD HERE";

function WardrobeLock() {
  const { wardrobeLockSolved } = useSelector((state) => state.StageFirst);

  const dispatch = useDispatch();

  const [input, setInput] = React.useState(wardrobeLockSolved ? UNLOCKED : "");
  const [inputDisabled, setInputDisabled] = React.useState(false);

  useEffect(() => {
    if (input === ANSWER) {
      setInput(UNLOCKED);
      dispatch(toggleShowWardrobeBubblePopup(true));
      dispatch(setWardrobeLockSolved());
      dispatch(setCheckNoseLock(RED_HIGHLIGHTER_STATE.HIGHLIGHT));
      // auto close wardrobe lock after 2 seconds once unlocked
      setTimeout(() => {
        dispatch(toggleShowWardrobeLock());
      }, 2000);
    } else if (input.length === 11) {
      // Using a function within setState for accurate previous state
      setInput(INCORRECT);
      setInputDisabled(true);

      setTimeout(() => {
        setInput("");
        setInputDisabled(false);
      }, 2000);
    }
  }, [input, dispatch]);

  return (
    <>
      <div
        style={{
          height: "95vh",
          width: "100%",
          backgroundImage: `url(${
            wardrobeLockSolved
              ? ImageUrls.StageFirst.WardrobeLock.unlocked
              : ImageUrls.StageFirst.WardrobeLock.locked
          })`,
          backgroundSize: "100% 100%",
        }}
        className="wardrobe_lock"
      >
        <div className="wardrobe_lock_answer">
          <input
            className={`${inputDisabled ? "shake" : ""}`}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            style={{
              color:
                input === INCORRECT
                  ? "red"
                  : input === ANSWER || wardrobeLockSolved
                  ? "green"
                  : "black",
            }}
            placeholder={INPUT_PLACEHOLDER}
            value={input}
            type="text"
            name=""
            id=""
          />
        </div>
        <div className="button_grid_container">
          {buttonRows.map((row, rowIndex) => {
            return (
              <div className="button_row" key={uuid.v4()}>
                {Array.from(Array(row).keys()).map((button, letterIndex) => {
                  const isLastButton =
                    rowIndex === buttonRows.length - 1 &&
                    letterIndex === buttonRows[buttonRows.length - 1] - 1;
                  return (
                    <div
                      className="button"
                      key={uuid.v4()}
                      style={{
                        backgroundColor: isLastButton ? "red" : "#e2c084",
                      }}
                      onClick={() => {
                        // disable buttons of wardrobe is unlocked
                        if (wardrobeLockSolved) {
                          return;
                        }

                        // disable buttons if input is incorrect
                        if (inputDisabled) {
                          return;
                        }

                        // check if last button
                        if (isLastButton) {
                          setInput("");
                        } else {
                          if (input.length >= 11) {
                            return;
                          }
                          setInput(
                            input +
                              (rowIndex === 0
                                ? String.fromCharCode(65 + letterIndex)
                                : rowIndex === 1
                                ? String.fromCharCode(70 + letterIndex)
                                : rowIndex === 2
                                ? String.fromCharCode(76 + letterIndex)
                                : rowIndex === 3
                                ? String.fromCharCode(82 + letterIndex)
                                : rowIndex === 4
                                ? String.fromCharCode(88 + letterIndex)
                                : ""),
                          );
                        }
                      }}
                    >
                      {isLastButton
                        ? "DEL"
                        : rowIndex === 0
                        ? String.fromCharCode(65 + letterIndex)
                        : rowIndex === 1
                        ? String.fromCharCode(70 + letterIndex)
                        : rowIndex === 2
                        ? String.fromCharCode(76 + letterIndex)
                        : rowIndex === 3
                        ? String.fromCharCode(82 + letterIndex)
                        : rowIndex === 4
                        ? String.fromCharCode(88 + letterIndex)
                        : ""}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default WardrobeLock;
