import { createSlice } from "@reduxjs/toolkit";
import {
  letters,
  selectableColumns,
  selectableRows,
} from "StaticData/SearchWord";

const WordSearch = createSlice({
  name: "WordSearch",
  initialState: {
    letters,
    selectableRows,
    selectableColumns,
    selectedItems: letters.map(() => false),
    isErasing: false,
    showFirstColumnAndGrid: false,
  },
  reducers: {
    selectLetter: (state, action) => {
      const newSelectedItems = [...state.selectedItems];
      action.payload.forEach((id) => {
        newSelectedItems[id] = true;
      });
      state.selectedItems = newSelectedItems;
    },
    deselectLetter: (state, action) => {
      const newSelectedItems = [...state.selectedItems];
      action.payload.forEach((id) => {
        newSelectedItems[id] = false;
      });
      state.selectedItems = newSelectedItems;
    },
    toggleLetterSelection: (state, action) => {
      const newSelectedItems = [...state.selectedItems];
      newSelectedItems[action.payload.id] = action.payload.show;
      state.selectedItems = newSelectedItems;
    },
    toggleisErasing: (state) => {
      state.isErasing = !state.isErasing;
    },
    clearAllSelection: (state) => {
      state.selectedItems = state.selectedItems.map(() => false);
      state.isErasing = false;
    },
    setShowFirstColumnAndGrid: (state, action) => {
      state.showFirstColumnAndGrid = true;
    },
  },
});

export const {
  selectLetter,
  deselectLetter,
  toggleLetterSelection,
  toggleisErasing,
  clearAllSelection,
  setShowFirstColumnAndGrid,
} = WordSearch.actions;

export default WordSearch.reducer;
