import { ImageUrls } from "Assets";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./InformationPopup.scss";
import CancelButton from "Components/CancelButton/CancelButton";
import Checkbox from "Components/Checkbox/Checkbox";

const uuid = require("uuid");

const Instuctions = [
  "Start by clicking everything in room to understand the puzzles you need to solve. Then, use the clues placed around each room to help you solve the puzzles.",
  "All clues in each room will be used.",
  "You are allowed to use Google or other search engines to help you solve puzzles.",
  "Make sure to delegate tasks across all of your team members to achieve the fastest escape time possible.",
  "If you have any questions, don't hesitate to ask your Host.",
];

function InformationPopup() {
  const { stageFirstComplete } = useSelector((state) => state.StageFirst);

  const [showInfo, setShowInfo] = useState(false);
  const [isCheckedFirst] = useState(stageFirstComplete);
  const [isCheckedSecond] = useState(false);
  const [isCheckedThird] = useState(false);

  return (
    <>
      {showInfo ? (
        <>
          {
            <CancelButton
              onClick={() => setShowInfo(!showInfo)}
              top={5}
              right={7}
            />
          }

          <div
            style={{
              backgroundImage: `url(${ImageUrls.StageFirst.paperBg})`,
              backgroundSize: "90% 90%",
              backgroundPosition: "30% 30%",
              backgroundRepeat: "no-repeat",
            }}
            className="informationPopup__content"
          >
            <div className="informationPopup__logo">
              <img src={ImageUrls.logo} alt="" />
            </div>
            <div className="informationpopup__text_content">
              <div className="informationpopup__left">
                <div className="informationpopup__left__title informationpopup__title">
                  <h1>Tips:</h1>
                </div>
                <div className="informationpopup_line_items">
                  {Instuctions.map((text) => (
                    <LineItem key={uuid.v4()} text={text} />
                  ))}
                </div>
              </div>
              <div className="informationpopup__right">
                <div className="informationpopup__right__title informationpopup__title">
                  <h1>Objectives:</h1>
                </div>
                <div className="informationpopup_line_items">
                  <div className="informationpopup_line_item">
                    <Checkbox
                      label={"Escape The Krinch’s Dungeon"}
                      checked={isCheckedFirst}
                      onChange={() => {}}
                    />
                  </div>
                  <div className="informationpopup_line_item">
                    <Checkbox
                      label={"Find out why The Krinch hates Christmas so much"}
                      checked={isCheckedSecond}
                      onChange={() => {}}
                    />
                  </div>
                  <div className="informationpopup_line_item">
                    <Checkbox
                      label={"Make it back in time for the Great Jubilation"}
                      checked={isCheckedThird}
                      onChange={() => {}}
                    />
                  </div>
                </div>
                {!stageFirstComplete && (
                  <div className="informationpopup_video">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/coLy-j4kv6Y"
                      title="Everything You Want To Know About Runaway Games"
                      frameBorder="0"
                      allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="info_icon" onClick={() => setShowInfo(!showInfo)}>
          <img src={ImageUrls.infoIcon} alt="" />
        </div>
      )}
    </>
  );
}

const LineItem = ({ text }) => {
  return (
    <div className="line_item">
      <img src={ImageUrls.InformationPopup.bulb} alt="" />
      <p>{text}</p>
    </div>
  );
};

export default InformationPopup;
