import React, { useEffect } from "react";

import "./StageFirst.scss";

import { useNavigate } from "react-router-dom";
import { ObjectsToHighlight, getBaseImage } from "./Highlighter";
import { useDispatch, useSelector } from "react-redux";
import { ImageUrls } from "Assets";
import {
  setCheckNoseLock,
  setCheckPrinter,
  setCheckSpotTheDiff,
  setCheckWordPuzzle,
  // FOR TEST BUTTONS
  // setWardrobeLockSolved,
  toggleNoseLockImage,
  toggleShowDoorLock,
  toggleShowNumberLogicGame,
  toggleShowPrinterBubblePopup,
  toggleShowSpotTheDiffGame,
  toggleShowSpotTheDiffSolImage,
  toggleShowWardrobeBubblePopup,
  toggleShowWardrobeLock,
  toggleShowWordSearchGame,
} from "Store/StageFirst";
// FOR TEST BUTTONS
// import { gameCompleted } from "Store/CrazyNumLogic";
import { RED_HIGHLIGHTER_STATE } from "Shared/Constants";
import InformationPopup from "Components/InformationPopup/InformationPopup";
import DoorLock from "Components/DoorLock/DoorLock";
import WardrobeLock from "Components/WardrobeLock/WardrobeLock";
import CrazyNumLogic from "Components/CrazyNumLogic/CrazyNumLogic";
import SpotTheDiff from "Components/SpotTheDiff/SpotTheDiff";
import SearchWord from "Components/WordSearch/SearchWord";
import ImagePopup from "Components/ImagePopup/ImagePopup";
import GamePopUp from "Components/GamePopup/GamePopup";

const uuid = require("uuid");

function StageFirst() {
  const { isCompleted: NumberLogicCompelted } = useSelector(
    (state) => state.CrazyNumLogic,
  );

  const StageFirstState = useSelector((state) => state.StageFirst);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [activeImage, setActiveImage] = React.useState(
    getBaseImage(NumberLogicCompelted),
  );

  // red hgihlighter state checkers

  useEffect(() => {
    if (StageFirstState.checkPrinter === RED_HIGHLIGHTER_STATE.HIGHLIGHT) {
      setActiveImage(
        ImageUrls.StageFirst.withPaper.redPrinterAndPaperHighlighted,
      );
    } else if (
      StageFirstState.checkPrinter === RED_HIGHLIGHTER_STATE.UNHIGHLIGHT
    ) {
      setActiveImage(getBaseImage(NumberLogicCompelted));
      dispatch(setCheckPrinter(RED_HIGHLIGHTER_STATE.NONE));
    }
  }, [StageFirstState.checkPrinter, NumberLogicCompelted, dispatch]);

  useEffect(() => {
    if (StageFirstState.checkSpotTheDiff === RED_HIGHLIGHTER_STATE.HIGHLIGHT) {
      setActiveImage(
        NumberLogicCompelted
          ? ImageUrls.StageFirst.withPaper.redSpotTheDiffHiglighted
          : ImageUrls.StageFirst.noPaper.redSpotTheDiffHiglighted,
      );
    } else if (
      StageFirstState.checkSpotTheDiff === RED_HIGHLIGHTER_STATE.UNHIGHLIGHT
    ) {
      setActiveImage(getBaseImage(NumberLogicCompelted));
      dispatch(setCheckSpotTheDiff(RED_HIGHLIGHTER_STATE.NONE));
    }
  }, [StageFirstState.checkSpotTheDiff, NumberLogicCompelted, dispatch]);

  useEffect(() => {
    if (StageFirstState.checkWordPuzzle === RED_HIGHLIGHTER_STATE.HIGHLIGHT) {
      setActiveImage(ImageUrls.StageFirst.withPaper.redWordPuzzleHiglighted);
    } else if (
      StageFirstState.checkWordPuzzle === RED_HIGHLIGHTER_STATE.UNHIGHLIGHT
    ) {
      setActiveImage(getBaseImage(NumberLogicCompelted));
      dispatch(setCheckWordPuzzle(RED_HIGHLIGHTER_STATE.NONE));
    }
  }, [StageFirstState.checkWordPuzzle, NumberLogicCompelted, dispatch]);

  useEffect(() => {
    if (StageFirstState.checkNoseLock === RED_HIGHLIGHTER_STATE.HIGHLIGHT) {
      setActiveImage(
        NumberLogicCompelted
          ? ImageUrls.StageFirst.withPaper.redNoseLockHiglighted
          : ImageUrls.StageFirst.noPaper.redNoseLockHiglighted,
      );
    } else if (
      StageFirstState.checkNoseLock === RED_HIGHLIGHTER_STATE.UNHIGHLIGHT
    ) {
      setActiveImage(getBaseImage(NumberLogicCompelted));
      dispatch(setCheckNoseLock(RED_HIGHLIGHTER_STATE.NONE));
    }
  }, [StageFirstState.checkNoseLock, NumberLogicCompelted, dispatch]);

  // red highlighter state checkers end

  return (
    <>
      {/* Test Buttons */}
      {/* <button
        style={{
          position: "absolute",
          top: "5%",
          left: "40%",
        }}
        onClick={() => {
          dispatch(gameCompleted());
          dispatch(setCheckPrinter(RED_HIGHLIGHTER_STATE.HIGHLIGHT));
        }}
        className="test_button"
      >
        Solve Computer
      </button>
      <button
        style={{
          position: "absolute",
          top: "5%",
          right: "40%",
        }}
        className="test_button"
        onClick={() => {
          dispatch(setWardrobeLockSolved());
          dispatch(setCheckNoseLock(RED_HIGHLIGHTER_STATE.HIGHLIGHT));
          dispatch(toggleShowWardrobeBubblePopup(true));
        }}
      >
        Solve Wardrobe
      </button> */}
      {/* Buttons */}
      <InformationPopup />

      {/* Popups */}
      <ImagePopup
        show={StageFirstState.showSpotTheDiffSolImage}
        image={ImageUrls.StageFirst.spotTheDifferenceSolImage}
        toggle={toggleShowSpotTheDiffSolImage}
        ImgHeight="100%"
        ImgWidth="100%"
      />

      <ImagePopup
        show={StageFirstState.showNoseLockImage}
        image={
          StageFirstState.wardrobeLockSolved
            ? ImageUrls.StageFirst.NoseLock.stateTwo
            : ImageUrls.StageFirst.NoseLock.stateOne
        }
        toggle={toggleNoseLockImage}
        ImgHeight="100%"
        ImgWidth="100%"
      />

      <ImagePopup
        show={StageFirstState.showWardrobeBubblePopup}
        image={ImageUrls.StageFirst.WardrobeSolvePop}
        toggle={toggleShowWardrobeBubblePopup}
        ImgHeight="80%"
        ImgWidth=""
        cancelTop="32"
        cancelRight="32"
        // Special handling of zIndex of this popup due its collusion with wardrobe lock
        cancelZIndex={9}
        zIndex={8}
      />

      <ImagePopup
        show={StageFirstState.showPrinterBubblePopup}
        image={ImageUrls.StageFirst.printerBubblePopUp}
        toggle={toggleShowPrinterBubblePopup}
        ImgHeight="80%"
        ImgWidth=""
        cancelTop="32"
        cancelRight="32"
        // Special handling of zIndex of this popup due its collusion with wardrobe lock
        cancelZIndex={9}
        zIndex={8}
      />

      {/* Game Popups */}
      <GamePopUp
        show={StageFirstState.showDoorLock}
        toggle={() => {
          dispatch(toggleShowDoorLock());
        }}
      >
        <DoorLock />
      </GamePopUp>

      <GamePopUp
        show={StageFirstState.showWardrobeLock}
        toggle={() => {
          dispatch(toggleShowWardrobeLock());
        }}
      >
        <WardrobeLock />
      </GamePopUp>

      <GamePopUp
        show={StageFirstState.showNumberLogicGame}
        toggle={() => {
          dispatch(toggleShowNumberLogicGame());
        }}
      >
        <CrazyNumLogic />
      </GamePopUp>

      <GamePopUp
        show={StageFirstState.showSpotTheDiffGame}
        toggle={() => {
          dispatch(toggleShowSpotTheDiffGame());
        }}
      >
        <SpotTheDiff />
      </GamePopUp>

      <GamePopUp
        show={StageFirstState.showWordSearchGame}
        toggle={() => {
          dispatch(toggleShowWordSearchGame());
        }}
        cancelBtnRight={"23"}
      >
        <SearchWord />
      </GamePopUp>

      {/* Object highlighter */}
      {Object.values(ObjectsToHighlight).map((object) => {
        if (!object.isVisible(NumberLogicCompelted, StageFirstState)) {
          return <div key={uuid.v4()}></div>;
        }

        return (
          <div
            key={uuid.v4()}
            style={{
              position: "absolute",
              top: `${object.position.y}%`,
              left: `${object.position.x}%`,
              transform: "translate(-50%, -50%)",
              width: object.width,
              height: object.height,
              cursor: "pointer",
            }}
            onMouseEnter={() => {
              // disable it if check printer is on
              if (
                StageFirstState.checkPrinter ===
                  RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
                StageFirstState.checkSpotTheDiff ===
                  RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
                StageFirstState.checkNoseLock ===
                  RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
                StageFirstState.checkWordPuzzle ===
                  RED_HIGHLIGHTER_STATE.HIGHLIGHT
              )
                return;
              setActiveImage(object.getImage(NumberLogicCompelted));
            }}
            onMouseLeave={() => {
              // disable it if check printer is on
              if (
                StageFirstState.checkPrinter ===
                  RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
                StageFirstState.checkSpotTheDiff ===
                  RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
                StageFirstState.checkNoseLock ===
                  RED_HIGHLIGHTER_STATE.HIGHLIGHT ||
                StageFirstState.checkWordPuzzle ===
                  RED_HIGHLIGHTER_STATE.HIGHLIGHT
              )
                return;
              setActiveImage(getBaseImage(NumberLogicCompelted));
            }}
            onClick={() => {
              object.onClick(navigate, StageFirstState, dispatch);
            }}
          ></div>
        );
      })}
      {/* BaseImage */}
      <div
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        }}
        className="stage_first"
      >
        <img
          style={{
            height: "100%",
            width: "100%",
          }}
          src={activeImage}
          alt=""
        />
      </div>
    </>
  );
}

export default StageFirst;
