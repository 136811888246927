import React from "react";

import "./CancelButton.scss";
import { ImageUrls } from "Assets";

function CancelButton({ onClick, top = 2.5, right = 3, zIndex = 10 }) {
  return (
    <div
      style={{
        top: `${top}%`,
        right: `${right}%`,
        zIndex: zIndex,
      }}
      className="cancel_button"
      onClick={onClick}
    >
      <img src={ImageUrls.cancel} alt="" />
    </div>
  );
}

export default CancelButton;
