import CancelButton from "Components/CancelButton/CancelButton";

const GamePopUp = ({
  children,
  show,
  toggle,
  cancelBtnRight = 1,
  cancelBtnTop,
}) => {
  if (!show) return null;

  return (
    <>
      <CancelButton
        onClick={toggle}
        right={cancelBtnRight}
        top={cancelBtnTop}
      />
      <div
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
          position: "fixed",
          zIndex: 9,
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",

          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            width: "95vw",
            overflow: "hidden",
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default GamePopUp;
